import React, { useEffect } from 'react';

import Footer from '../Footer/Footer';
import img from '../../Assets/heroImg.jpeg';
import './Guide.css'

import GuideHero from './GuideHero/GuideHero';
import ApiDocumentation from './GuideContent/ApiDocumentation';

const Guides = () => {

  // open the page on the top 
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <GuideHero />
      <ApiDocumentation />
      <Footer />
    </>
  );
};

export default Guides;

