import React from "react";
import "./Plans.css";
import { FreeBreakfast } from "@mui/icons-material";
import { faQuinscape } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { AllPlansData } from "../APIs/Api";
import Footer from '../../Components/Footer/Footer'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Bars, Hourglass } from "react-loader-spinner";


const Plans = () => {
  const customId = "forNotShowingMultipleToast"
  const navigate = useNavigate()

  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const APIKey = localStorage.getItem('APIKey');
  const APIName = localStorage.getItem('APIName') // Getting the api name for plan purchase 

  const [PlansData, setPlansData] = useState([]);
  const [AllDataResponse, setAllDataResponse] = useState('')

  const AllPlansDataApi = async () => {
    const result = await AllPlansData();
    setAllDataResponse(result.status)
    if (result.status === 1) {
      const sortedData = result.data.sort((a, b) => a.id - b.id);
      setPlansData(result.data);
      // setAllDataResponse(result.status)
    }

  }

  useEffect(() => {
    AllPlansDataApi();
    window.scrollTo(0, 0)
  }, [])

  // buy plan api function start here 

  const handleMakePayment = (planPrice, planName, planCategory) => {
    if (!APIKey) {
      toast.warn("You need to login First !", {
        position: 'top-right',
        autoClose: 2000,
        toastId: customId,
      });
      navigate('/login')

    }
    const myHeaders = new Headers();
    myHeaders.append("X-API-Key", `${APIKey}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "price": planPrice,
      "api_name": APIName,
      "plan_name": planName,
      "plan_category": planCategory
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${REACT_APP_BASE_URL}/payment/plan/purchase`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          localStorage.setItem('SessionId', result.data.SessionID);

          // Redirect the browser to the session URL
          window.location.href = result.data.SessionURL;
        } else if (result.message === "Payment processing failed: you cannot opt for free trial") {
          // Show toast for free trial restriction
          toast.error(result.message, {
            position: 'top-right',
            autoClose: 3000,
            toastId: customId,
          });
        } else {
          // Handle other error messages
          toast.error(result.message || "You can purchase the Free Trial only once.", {
            position: 'top-right',
            autoClose: 3000,
            toastId: customId,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("You can purchase the Free Trial only once.", {
          position: 'top-right',
          autoClose: 3000,
        });
      });
  };


  return (
    <>{
      AllDataResponse === 1 ?
        <div className="plans">
          <h1 className="plans__header"><span>({APIName})</span> Pick the Perfect Plan for Your Vision</h1>
          <div className="plans__container">

            {PlansData.sort((a, b) => a.id - b.id).map((plan) => (
              <div className='plan-card' key={plan.id} style={{ marginBottom: '30px' }} >
                {plan.isBestDeal && <div className="plan-card__badge">Best Deal</div>}
                <div style={{ height: '300px' }}>
                  <div className="plan-card__icon"><FontAwesomeIcon icon={faQuinscape} /></div>
                  <h2 className="plan-card__title">{plan.plan_name}</h2>

                  <div className="plan-card__pricing">
                    {/* <span className="plan-card__original-price">{plan.originalPrice}</span>
              <span className="plan-card__discount-tag" >

                <span className="discount-icon"></span> {plan.discountPercentage}
              </span> */}
                    <span className="plan-card__discounted-price"> $ {plan.price}</span>
                  </div>

                  {plan.api_request_limit === 0 && <p className="plan-card__validity">Validity:<span className="ValidateValue"> {plan.duration_days} Days</span></p>}
                  <p className="plan-card__validity">APIs Request Limit: <span style={{ color: 'grey', fontWeight: "500" }}>{plan.is_unlimited ? "Unlimited Request" : plan.api_request_limit}</span> </p>

                  <ul className="plan-card__advantages">

                    <li className="plan-card__advantage">
                      <p className="advantage-icon">Description:<span style={{ color: "gray", fontSize: '14px', fontWeight: '400' }}>{plan.description === "" ? "You can hit the only your given APIs request limit" : plan.description}</span></p>


                    </li>
                  </ul>
                </div>

                <button className="plan-card__cta" onClick={() => handleMakePayment(plan.price, plan.plan_name, plan.category)}>Choose Plan</button>
              </div>
            ))}
          </div>

        </div> : AllDataResponse === 0 ? <>Error</>
          : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60vh" }}>
            <Bars
              visible={true}
              height="40"
              width="40"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              color="rgb(76,114,175)"
            />
          </div>

    }


      <Footer />
    </>
  );
};

export default Plans;
