import React, { useEffect } from 'react';
import './DeviceStatusMonitoring.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import DeviceStatusMonitoringApiReference from './swaggerDeviceApi/DeviceStatusMonitoringApiReference';
import Footer from '../../Footer/Footer';
// import ApiReference from './ApiReference/ApiReference';

const DeviceStatusMonitoring = () => {
    const [TabValue, setTabValue] = useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // for open the page to the top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <Box className='DeviceStatusBox' sx={{ typography: 'body1' }}>
                <div className='DeviceStatusHeading'>
                    <h1>Device Location Verification</h1>
                </div>
                <div className='DeviceStatusTabDetails'>
                    <TabContext value={TabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Overview" value="1" />
                                <Tab label="API Reference" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div className='OverviewDetails'>
                                <p className='main-heading'>Device  Location Verification <span style={{ fontSize: '18px', fontWeight: '500', }}>API is part of </span><span id='ApiDocs' style={{ textDecoration: 'underline' }}>startelelogic APIs.</span></p>
                                <p>The startelelogic suite of APIs includes the Device Location Verification  API, which is intended to give users access to real-time information on the operational state of network-connected devices.</p>
                                <h3>What it does?</h3>
                                <p>Real-time status checks on network-connected devices are carried out via the Device  Location Verification, which provides insightful information on the devices' present condition and any changes in it.</p>
                                <p>For monitoring device health and guaranteeing peak network performance, this API is crucial.</p>
                                <p>It effectively controls resources and answers important queries like:</p>
                                <ul>
                                    <li>What is the device's current state?</li>
                                    <li>The device's status was last updated when?</li>
                                </ul>
                            </div>
                        </TabPanel>
                        <TabPanel value="3">
                            <DeviceStatusMonitoringApiReference />
                        </TabPanel>
                    </TabContext>
                </div>
            </Box>
            <Footer />
        </>
    );
};

export default DeviceStatusMonitoring;
