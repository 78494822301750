
import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Container, Typography, IconButton } from '@mui/material';
import { Email, MessageSharp, Phone, Send } from '@mui/icons-material';
import './ContactPage.css';
import ContactUsImage from '../../Assets/contact-us.png'
import Footer from '../Footer/Footer';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContactHero from './ContactHero';
import icon1 from '../../Assets/reliability.png'
import icon2 from '../../Assets/live-chat.png'
import supportImg from '../../Assets/support-contact.png'
const ContactPage = () => {
  const [support, setSupport] = useState(true);

   const customId = "forNotShowingMultipleToast"

  // form states
  const [FullName, setFullName] = useState('');
  const [Email, setEmail] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [Message, setMessage] = useState('');

  // Error message states
  const [FullNameError, setFullNameError] = useState('');
  const [EmailError, setEmailError] = useState('');
  const [MobileNoError, setMobileNoError] = useState('');
  const [MessageError, setMessageError] = useState('');

  const [FullNameFlag, setFullNameflag] = useState(true);
  const [EmailFlag, setEmailFlag] = useState(true);
  const [MobileNoFlag, setMobileNoFlag] = useState(true);
  const [MessageFlag, setMessageFlag] = useState(true);


  // open the page on the top 

  useEffect(() => {
    window.scrollTo(0,0)
  },[]);



  // validation start here

  const handleFullName = (e) => {
    const inputValue = e.target.value;

    setFullName(inputValue);

    // If the input value is blank
    if (inputValue.trim() === "") {
      setFullNameError("Please enter full name");
      setFullNameflag(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
      setFullName(alphabeticValue);
      setFullNameError("");
      setFullNameflag(true);
    }


  }

  const handleEmail = (e) => {
    const inputEmail = e.target.value;

    setEmail(inputEmail);
    ValidateEmail(inputEmail);
  };

  const ValidateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!email) {
      setEmailError("Please enter your email id");
      setEmailFlag(false);
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
      setEmailFlag(false);
    } else {
      setEmailError(""); // Clear any previous error
      setEmailFlag(true); // Set flag to true for valid email
    }
  };


  const handleMobileNo = (Mobile) => {
    if (Mobile.trim() === "") {
      setMobileNoError('Please enter contact number');
    } else {
      setMobileNoError(''); // It should clear the mobile number error
    }
    setMobileNo(Mobile);
  }

  const handleMessage = (e) => {
    const inputValue = e.target.value;

    setMessage(inputValue);

    // If the input value is blank
    if (inputValue.trim() === "") {
      setMessageError("Please enter message");
      setMessageFlag(false);
    } else {
      setMessageError("");
      setMessageFlag(true);
    }
  }

  const handleSubmit = async () => {
    // Validation checks
    if (!FullName) {
      setFullNameError('Please enter full name');
    } else {
      setFullNameError('');
    }
  
    if (!Email) {
      setEmailError('Please enter your email id');
    } else {
      setEmailError('');
    }
  
    if (!MobileNo) {
      setMobileNoError('Please enter your contact number');
    } else {
      setMobileNoError('');
    }
  
    if (!Message) {
      setMessageError('Please enter message');
    } else {
      setMessageError('');
    }
  
    if (!FullName || !Email || !MobileNo || !Message) {
      toast.warn('Please fill in all required fields', {
        toastId: customId,
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    }
  
    // API call
    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbwrBtq2p7dD_CnWmxDdxxxJpPTcWWqZHa-W_RXAKxvetDQXjYB3BxaU4jlDl8gWRIy0yQ/exec',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            name: FullName,
            email: Email,
            mobile_no: MobileNo,
            message: Message,
          }),
        }
      );
  
      const result = await response.json();
  
      if (response.ok && result.message === 'Data saved successfully.') {
        toast.success('Form submitted successfully!', {
          toastId: customId,
          position: 'top-right',
          autoClose: 2000,
        });
  
        // Clear fields after successful submission
        setFullName('');
        setEmail('');
        setMobileNo('');
        setMessage('');
      } else {
        toast.error(`Error: ${result.error || 'Failed to submit data'}`, {
          toastId: customId,
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`, {
        toastId: customId,
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };





  return (
    <>
      <div className="contact-page-container">
        <ContactHero/>
        <div className="contentBg">
          <div className="icon-feature">
        
              <div className='icon-container'>
              <img className='iconFeature' src={icon1} alt='icon-support'/>
              <p className='icon-text'>Our team is ready to provide the support </p>
          
             
              </div>
              <div className='icon-container'>
              <img className='iconFeature' src={icon2} alt='icon -availablity'/>
              <p  className='icon-text'>Have Questions? We are Here to Help!</p>
              </div>
     
            
          </div>

       
        {/* <div className='contact-us-text'>
          <span>Contact Us</span>
          <p>Please fill out the form below with your details and tell us what assistance or information you're looking for. Our team is here to address any questions or concerns you may have, and we will ensure that you receive the answers you need as quickly as possible.</p>
        </div> */}
 {/* <div className='contact-us-text'>
          <span>Contact Us</span>
          <p>Please fill out the form below with your details and tell us what assistance or information you're looking for. Our team is here to address any questions or concerns you may have, and we will ensure that you receive the answers you need as quickly as possible.</p>
        </div> */}




        <div className='main_box'>
          <div className="containMain-page">

            {/* <div className="contact-toggle">
              <div
                className="support-toggle"
                style={{ backgroundColor: support ? '#6D9EC0' : '' }}
                onClick={() => setSupport(true)}
              >
                <p>General Support</p>
              </div>
              <div
                className="technical-toggle"
                style={{ backgroundColor: support ? '' : '#6D9EC0' }}
                onClick={() => setSupport(false)}
              >
                <p>Technical Support</p>
              </div>
            </div> */}




            <div className='form' >
              {support === true ? (
                <>
                  <h2 gutterBottom style={{textAlign:'center', color:"#4464F0"}}>
                    Get in Touch
                  </h2>
                  <p gutterBottom  >
                    We’d love to hear from you. Fill out the form below to reach out!
                  </p>

                  <form>

                    {/* <input  /> */}
                    <div>
                      <TextField
                        label="Full Name"
                        variant="outlined"
                        value={FullName}
                        onChange={handleFullName}
                        fullWidth
                        required
                        margin="normal"
                      />
                      {FullNameError && (
                        <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{FullNameError}</p>
                      )}
                    </div>

                    <div>
                      <TextField
                        label="Email"
                        variant="outlined"
                        value={Email}
                        onChange={handleEmail}
                        fullWidth
                        required
                        margin="normal"
                      // InputProps={{
                      //   startAdornment: (
                      //     <IconButton>
                      //       <Email />
                      //     </IconButton>
                      //   ),
                      // }}
                      />
                      {EmailError && (
                        <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{EmailError}</p>
                      )}
                    </div>

                    <div>

                      <PhoneInput
                        country={"in"}
                        enableSearch={true}
                        inputProps={{ tabIndex: "3" }}
                        value={MobileNo}
                        onChange={handleMobileNo}
                        style={{
                          width: "100%",
                        }}
                        inputStyle={{
                          margin: '10px 0px',
                          width: "100%",
                          height: '54px',
                          background:'transparent'
                        }}
                      />
                      {MobileNoError && (
                        <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{MobileNoError}</p>
                      )}
                    </div>

                    <div>
                      <TextField
                        label="Message"
                        variant="outlined"
                        value={Message}
                        onChange={handleMessage}
                        fullWidth
                        required
                        margin="normal"
                        multiline
                        rows={4}
                      // InputProps={{
                      //   style: { backgroundColor: 'white', }, // Example custom styling
                      // }}
                      />
                      {MessageError && (
                        <p style={{ color: "red", fontSize: "13px", marginTop: '10px', }}>{MessageError}</p>
                      )}
                    </div>



                    <Button variant="outlined" color='inherit' onClick={handleSubmit}  >
                      Submit  <ArrowForwardIcon className="arrow" />
                    </Button>

                  </form>
                </>
              ) : (
                <>
                  <h2 variant="h4" gutterBottom>
                    Get in Touch
                  </h2>
                  <Typography variant="subtitle1" gutterBottom  >
                    We’d love to hear from you. Fill out the form below to reach out!
                  </Typography>

                  <form>
                    <TextField
                      label="Full Name"
                      variant="standard"
                      fullWidth
                      required
                      margin="normal"
                    />

                    <TextField
                      label="Email"
                      variant="standard"
                      fullWidth
                      required
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <IconButton>
                            <Email />
                          </IconButton>
                        ),
                      }}
                    />

                    <TextField
                      label="Phone Number"
                      variant="standard"
                      fullWidth
                      required
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <IconButton>
                            <Phone />
                          </IconButton>
                        ),
                      }}
                    />

                    <TextField
                      label="Message"
                      variant="standard"
                      fullWidth
                      required
                      margin="normal"
                      multiline
                      rows={4}
                    />

                    <Button variant="contained" color="primary" >
                      Submit
                    </Button>

                  </form>
                </>
              )}

            </div>
            
            </div>
            <div className="right-image">
            <img className='support-img' src={supportImg} alt='support-img'/>

          </div>
          {/* <div className='contact-image'>

            <img src={ContactUsImage} alt="" height={550} />

          </div> */}


          </div>

        </div>

        <div className="map-container">
    

          <iframe 
           title="Our Location"
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d112095.71958620487!2d77.24945419170268!3d28.600039696554674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390ce4503fffffff%3A0x5ad8f15161798a45!2sF%2023%2C%20F%20Block%2C%20Sector%2011%2C%20Noida%2C%20Uttar%20Pradesh%20201301!3m2!1d28.6000646!2d77.3318556!5e0!3m2!1sen!2sin!4v1730716217216!5m2!1sen!2sin"
           width="100%"
            height="450" 
            style={{border:0}} allowfullscreen 
          loading="lazy"></iframe>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
