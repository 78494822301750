
import React from 'react'
import { Tab } from '@mui/material'
import DownloadingIcon from '@mui/icons-material/Downloading';
import './ApiReference.css'
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { lookup } from 'country-data';  // import the country-data package


const ApiReference = () => {

  const REACT_APP_BASE_URL_NETWORK = process.env.REACT_APP_BASE_URL_NETWORK;
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const LoginStatus = localStorage.getItem('isLoggedIn')
  const [country, setCountry] = useState('');
  const UserUUId = localStorage.getItem('UserUUId')
  const AuthToken = localStorage.getItem('AuthToken'); // for getting the auth token from local storage
  const APIKey = localStorage.getItem('APIKey');

  // API Reference Tab panel states 
  const [ApiReferencevalue, setApiReferenceValue] = useState('1');

  const handleAPITab = (event, newValue) => {
    setApiReferenceValue(newValue);
  };


  // OpenApiSpec function calling for show the api name, url , parameter in the swagger and response status 

  const openApiSpec = {
    "openapi": "3.0.0",
    "info": {
      "title": "SIM Swap",
      "version": "1.0.0"
    },
    "components": {
      // "securitySchemes": {
      //   "bearerAuth": {
      //     "type": "http",
      //     "scheme": "bearer",
      //     "bearerFormat": "JWT"
      //   }
      // },

      "parameters": {
        "ContentType": {
          "name": "Content-Type",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": "application/json"
          },
          "description": "Content type for the request"
        },
        "APIKey": {
          "name": "X-API-Key",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": APIKey // Dynamically set the default from localStorage
          },
          "description": "API Key for authentication"
        }
      }
    },
    // "security": [
    //   {
    //     "bearerAuth": []
    //   }
    // ],
    "servers": [
      {
        "url": `${REACT_APP_BASE_URL_NETWORK}`
      }
    ],
    "paths": {
      "/sim-swap/check": {
        "post": {
          "tags": ["SIM Swap"],
          "summary": "SIM Swap ",
          "requestBody": {
            "description": "SIM Swap ",
            "required": true,
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "phoneNumber": {
                      "type": "string",
                      "default": "+33699901032"

                    },
                    "maxAge": {
                      "type": "integer",
                      "default": 60
                    },

                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Ok"
            },
            "400": {
              "description": "Bad Request"
            },
            "401": {
              "description": "Unauthorized"
            },
            "403": {
              "description": "Forbidden"
            },
            "404": {
              "description": "Not Found"
            },
            "405": {
              "description": "Method Not Allowed"
            },
            "500": {
              "description": "Internal Server Error"
            },
            "502": {
              "description": "Bad Gateway"
            },

          }
        }
      }
    }
  };



  const requestInterceptor = (req) => {
    if (APIKey) {
      req.headers['X-API-Key'] = APIKey;
    }
    return req;
  }


  // ResponesInterceptor for store the called api response for sending the logs apis parameter

  // const responseInterceptor = async (res) => {
  //   // const data = res.body;
  //   // console.log(data, 'dataaaaaaaaaaaaaaa');

  //   if (res.status === 401) {
  //     // Display a custom message to the user
  //     alert("Unauthorized access. Please access your token from the profile page.");
  //     window.location.href = '/user-setting';

  //     return;
  //   }

  //   const FirstName = localStorage.getItem('FirstName');
  //   const LastName = localStorage.getItem('LastName');

  //   const FullName = FirstName.trim() + " " + LastName.trim()

  //   // console.log(FullName, 'ffffffffffff')

  //   const date = new Date();

  //   // formatin the date 
  //   const UTCFormatDate = date.toISOString();



  //   const requestOptions = {
  //     method: "GET",
  //     redirect: "follow"
  //   };

  //   // Getting the Device ip address and network location and sending this details into an logs api

  //   const IPRes = await fetch("https://ipinfo.io/122.161.51.67?token=781b8c09d82125", requestOptions)

  //   const IPResponse = await IPRes.json();
  //   const countryCode = IPResponse.country;

  //   // Convert the iso2 to full country name 
  //   const getCountryName = (iso2Code) => {
  //     const countryData = lookup.countries({ alpha2: iso2Code })[0];
  //     return countryData ? countryData.name : 'Unknown Country';
  //   }

  //   const countryName = getCountryName(countryCode) || 'Unknown Country';
  //   // setCountry(countryName);






  //   //  logs api url 

  //   await fetch(`${REACT_APP_BASE_URL_NETWORK}/logs`, {
  //     method: 'POST', headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${AuthToken}`
  //     }, body: JSON.stringify({
  //       "status": "1",
  //       "method": "POST",
  //       "endpoint": "/get-device-location",
  //       "uuid": UserUUId,
  //       "name": FullName,
  //       "ipAddress": IPResponse.ip,
  //       "city": IPResponse.city,
  //       "region": IPResponse.region,
  //       "country": countryName,
  //       "location": IPResponse.loc,
  //       "org": IPResponse.org,
  //       "postal": IPResponse.postal,
  //       "timezone": IPResponse.timezone,
  //       "mac": "",
  //       "date": UTCFormatDate,
  //     }
  //     )
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       // console.log(data); 
  //     })

  //   return res;
  // }

  // Phone Number to be used for call the api

  const phoneNumbers = ["33699901031", "33699901032", "33699901033", "33699901034", "33699901035", "33699901036", "33699901037", "33699901038", "33699901039", "33699901040"];

  return (
    <>
      <div className="main-container-sim-swap">



        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={ApiReferencevalue}>
            {/* <Box sx={{ borderColor: 'divider' }}>
              <TabList onChange={handleAPITab} aria-label="lab API tabs example">
                <Tab label="SwaggerUI" value="1" />
                <Tab label="Rapidoc" value="2" />
              </TabList>
            </Box> */}
            <TabPanel value="1">
              <div className="swagger-mainContainer">
                <h2>SIM Swap</h2>
                <p>The SIM Swap API allows users to check whether a SIM swap has been completed on a specified mobile line.</p>


                <div className="introduction-content">
                  <h2>Introduction</h2>
                  <p>Users can check if a SIM swap has been finished on a particular mobile line using the SIM Swap API. It checks in real time whether a mobile number (MSISDN) has been moved to a different SIM card.</p>
                  <p>Through the improvement of SIM-based authentication procedures, such as SMS one-time passwords, this API plays a critical role in combating fraud. Fraudsters can intercept SMS communications, reset passwords, or get verification codes to access secure accounts by using SIM swapping tactics. This API reduces the chance of account takeovers by verifying the completeness of a SIM exchange.</p>
                  <p>Through the API, applications can find out when a mobile line's last SIM swap occurred and check the progress of SIM swaps. Service providers (SPs) can obtain this data in an easy-to-use and safe manner thanks to its easy integration. The API effectively allocates resources to address the following important query:</p>
                  <p><li>Has a SIM swap been completed on this mobile line?</li>
                    {/* <li>Has a SIM swap occurred during last n hours?</li> */}
                  </p>
                </div>



                <div className="relevent-terms-content">
                  <h2>Relevant terms and definitions</h2>
                  <p><li>
                    SIM swap: The procedure of deactivating and swapping out a user's existing SIM card for a new one. This could happen if you upgrade to a new phone, lose or break your SIM card, or change your phone number while keeping the same handset.
                  </li></p>



                </div>

                {LoginStatus !== "true" &&
                  <div className="swagger-info">
                    <h2  >How to Use Our APIs</h2>
                    <p>
                      Our APIs provide a range of services that you can explore through the documentation above.
                      You can use this interface to test API endpoints once you log in. For now, you can review the
                      available endpoints and see how to structure your requests.
                    </p>
                    <ul>
                      <li><strong>Authentication:</strong> Login to get your API Access.</li>
                      <li><strong>Access Token:</strong> After logging in, click on the username box on the right side and click on the user setting to navigate the user settings page. From there, copy your access token to use for API calls</li>
                      <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,Device Location Verification and OTP validation.</li>
                      <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
                    </ul>
                    <p>
                    </p>
                  </div>
                }


                <div className="api-functionality-content">
                  <h2>API functionality</h2>
                  <p>The API provides following operations:</p>
                  <p>
                    {/* <li>POST retrieve-date : Provides timestamp of latest SIM swap, if any, for a given phone number.

                      </li> */}
                  </p>

                  <p>
                    <li>POST check: Checks if SIM swap has been performed during a past period (defined in the request with 'maxAge' attribute) for a given phone number.

                    </li>
                  </p>



                </div>

                <div className='phone-number' >
                  <h2>Specific Number to API implementation</h2>
                  <p>Subscriber number in E.91 format (starting with country code). Optionally prefixed with '+'</p>
                  <p>Use this phoneNumber (other will not work):</p>
                  <p style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start', backgroundColor: 'lightgray', color: 'black', height: '50px', margin: '0px 0px ', padding: '0px 10px ', borderBottom: '1px solid gray'
                  }}>Phone Number</p>


                  {phoneNumbers.map((phonenumber, index) => {

                    return (
                      <>
                        <hr />
                        <p key={index} style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start', padding: '0px 10px',
                        }}>{phonenumber}  </p>
                      </>
                    )
                  })}

                </div>


                <div className="further-info-content-content">
                  <h2>Further-info-content</h2>
                  <p>This application programming interface makes use of the information contained within the GSMA Mobile Connect Account Takeover Protection definition. For more details on Mobile Connect, please visit the Mobile Connect website.
                  </p>
                  <p>(FAQs will be added in a later version of the documentation)</p>


                </div>
              </div>



              {
                LoginStatus === "true" ? (<Card className="hero-card">

                  {/* Show the swagger ui for loggedIn user  */}

                  {/* <SwaggerUI url="https://camara-api.callibry.com:3001/login"/> */}
                  <SwaggerUI spec={openApiSpec} requestInterceptor={requestInterceptor} />


                </Card>) : (
                  <>
                    <div className="login-message">
                      <a href='/login'>Please Login to Access Full API Features</a>
                      <p>
                        You need to log in to fully interact with the APIs, but you can still explore the API documentation below.
                      </p>
                    </div>

                    <Card className="hero-card">

                      {/* Render Swagger UI for non-logged-in users as read-only */}

                      <SwaggerUI

                        spec={openApiSpec}

                        // responseInterceptor={responseInterceptor}
                        docExpansion="none" // Collapse the docs for easier reading
                        supportedSubmitMethods={LoginStatus === "true" ? ['get', 'post', 'put', 'delete'] : []} // Disable try it out if not logged in
                      />
                    </Card>

                    {/* <div className="swagger-info">
                      <h3 style={{ marginTop: '20px' }} >How to Use Our APIs</h3>
                      <p>
                        Our APIs provide a range of services that you can explore through the documentation above.
                        You can use this interface to test API endpoints once you log in. For now, you can review the
                        available endpoints and see how to structure your requests.
                      </p>
                      <ul>
                        <li><strong>Authentication:</strong> Login to get your API Access.</li>
                        <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,Device status monitoring and OTP validation.</li>
                        <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
                      </ul>
                      <p>
                        For more information, please visit our <a href="/api-docs">API documentation page</a> or contact support.
                      </p>
                    </div> */}
                  </>
                )
              }

            </TabPanel>
            {/* <TabPanel value="2">Item Two</TabPanel> */}
          </TabContext>
        </Box>





      </div>

    </>
  )
}

export default ApiReference
