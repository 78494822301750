import React from 'react';
import './GuideHero.css' // Custom CSS for styling
// import guideImg from '../../../Assets/guideHeroCard1.avif'; // Replace with your image path
import MenuBookIcon from '@mui/icons-material/MenuBook';

const GuideHero = () => {
  return (
    <div className="guide-hero">
      {/* <div className="guideHero-bg" /> */}
      <div className="guide-hero-content">
        <h1 className="guide-hero-title">
        
        Explore the   <span style={{ color: "rgb(68, 100, 240)", fontSize: "3.5rem" }}>Guide</span> to use our APIs with ease
        </h1>
        <p className="guide-hero-subtitle">
        Discover how to seamlessly use our APIs into your projects with comprehensive, developer-friendly documentation. This guide covers all the essentials.

        </p>
        <a href="#introduction" className="guide-hero-btn">
          Get Started <MenuBookIcon className='guideHero-btn-icon' />
        </a>
      </div>
    </div>
  );
};

export default GuideHero;
