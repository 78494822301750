import React, { useState, useEffect } from "react";
import "./SignUp.css";
import StarteleLogo from '../../../Assets/Star.png'

// import { ReactComponent as SignUpAnimate } from "../../../Assets/signUpAnimate.svg";
import Typography from "@mui/material/Typography";
import { H2, H1, H3 } from "../../Typography";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { RotatingLines } from 'react-loader-spinner';
import { SignupApi } from "../../APIs/Api";
import { VerifyEmail } from "../../APIs/Api";
import { ResendOtp } from "../../APIs/Api";

import {
    Button,
    TextField,
    Box,
    OutlinedInput,

    Modal,
} from "@mui/material";
import { Password } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "10px",
    border: "0px",
    p: 3,
    animationName: "none",
};

const SignUp = () => {

    const customId = "forNotShowingMultipleToast"

    // loader button state 
    const [ShowSignUpButtonLoader, setShowSignUpButtonLoader] = useState(false)
    const [ShowSubmitOtpLoader, setShowSubmitOtpLoader] = useState(false)


    const [OpenVerifyEmailModal, setOpenVerifyEmailModal] = useState(false); // This state is for opening Verify Email Modal.
    const SignUpEmailForOtpVerify = localStorage.getItem(
        "SignUpEmailForOtpVerify"
    );
    const [VerifyOtp, setVerifyOtp] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0); // this is for scroll top of the page 
    }, []);

    const navigate = useNavigate();

    // For the Sign up Page States
    const [FirstName, setFirstName] = useState("");
    const [FirstNameFlag, setFirstNameFlag] = useState(true);
    const [LastName, setLastName] = useState('');
    const [LastNameFlag, setLastNameFlag] = useState(true)
    const [SignUpEmail, setSignUpEmail] = useState("");
    const [SignUpMobileNo, setSignUpMobileNo] = useState("");
    const [SignupEmailFlag, setSignupEmailFlag] = useState(true);
    const [SignUpPassword, setSignUpPassword] = useState("");
    const [ShowSigninPassword, setShowSigninPassword] = useState(false);
    const [SignupPasswordFlag, setSignupPasswordFlag] = useState(true);
    const [RetypeSignUpPassword, setRetypeSignUpPassword] = useState("");
    const [RetypeSignupPasswordFlag, setRetypeSignupPasswordFlag] = useState(true);
    const [ShowRetypePassword, setShowRetypePassword] = useState(false);


    // error message states 
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastNameError, setLastNameError] = useState('');
    const [MobileNoError, setMobileNoError] = useState('');
    const [SignupEmailError, setSignupEmailError] = useState("");
    const [SignupPasswordError, setSignupPasswordError] = useState("");
    const [RetypeSignupPasswordError, setRetypeSignupPasswordError] = useState("");

    const togglePasswordVisibility = (value, SetValue) => {
        SetValue(!value);
    };


    // Sign up page validation start here
    const handleFirstName = (e) => {
        const inputValue = e.target.value;
        setFirstName(inputValue);

        // If the input value is blank
        if (inputValue.trim() === "") {
            setFirstNameError("Please enter first name");
            setFirstNameFlag(false);
        } else {
            // Remove non-alphabetic characters
            const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
            setFirstName(alphabeticValue);
            setFirstNameError("");
            setFirstNameFlag(true);
        }
    };
    const handleLastName = (e) => {
        const inputValue = e.target.value;
        setLastName(inputValue);

        // If the input value is blank
        if (inputValue.trim() === "") {
            setLastNameError("Please enter last name");
            setLastNameFlag(false);
        } else {
            // Remove non-alphabetic characters
            const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");
            setLastName(alphabeticValue);
            setLastNameError("");
            setLastNameFlag(true);
        }
    };

    const handleSignUpEmailChange = (e) => {

        const SignUpEmail = e.target.value;
        setSignUpEmail(SignUpEmail);
        ValidateSignupEmail(SignUpEmail);
    };

    const ValidateSignupEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!email) {
            setSignupEmailError("Please enter your email id");
            setSignupEmailFlag(false);
        } else if (!emailPattern.test(email)) {
            setSignupEmailError("Invalid email address");
            setSignupEmailFlag(false);
        } else {
            setSignupEmailError(""); // Clear any previous error
            setSignupEmailFlag(true); // Set flag to true for valid email
        }
    };

    const handleMobileChange = (mobile) => {
        if (mobile.trim() === "") {
            setMobileNoError('Please enter contact number');
        } else {
            setMobileNoError(''); // It should clear the mobile number error
        }
        setSignUpMobileNo(mobile);
    }



    const maxPasswordLength = 16;

    const handleSignUpPassword = (e) => {
        const signuppasswordValue = e.target.value;

        if (signuppasswordValue.length <= maxPasswordLength) {
            setSignUpPassword(signuppasswordValue);
        }

        if (
            signuppasswordValue.length < 6 ||
            signuppasswordValue.length > maxPasswordLength
        ) {
            setSignupPasswordFlag(false);
            setSignupPasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters"
            );
        } else {
            setSignUpPassword(signuppasswordValue);
            setSignupPasswordError("");
            setSignupPasswordFlag(true);
        }
    };

    const handleRetypeSignupPassword = (e) => {
        const RetypesignuppasswordValue = e.target.value;

        if (RetypesignuppasswordValue.length <= maxPasswordLength) {
            setRetypeSignUpPassword(RetypesignuppasswordValue);
        }

        if (
            RetypesignuppasswordValue.length < 6 ||
            RetypesignuppasswordValue.length > maxPasswordLength
        ) {
            setRetypeSignupPasswordFlag(false);
            setRetypeSignupPasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters"
            );
        } else {
            setRetypeSignUpPassword(RetypesignuppasswordValue);
            setRetypeSignupPasswordError("");
            setRetypeSignupPasswordFlag(true);
        }
    };







    const HandleSignUp = async () => {
        if (!FirstName) {
            setFirstNameError("Please enter first name");
            setFirstNameFlag(false);
            setShowSignUpButtonLoader(false);
            // return;
        }
        if (!LastName) {
            setLastNameError("Please enter last name");
            setLastNameFlag(false);
            setShowSignUpButtonLoader(false);
            // return;
        }


        if (!SignUpEmail) {
            setSignupEmailError("Please enter your email id");
            setSignupEmailFlag(false);
            setShowSignUpButtonLoader(false);
            // return;
        }

        if (!SignUpMobileNo) {
            setMobileNoError('Please enter mobile number');
            setShowSignUpButtonLoader(false);
            // return;
        } else {
            setMobileNoError('');
            setShowSignUpButtonLoader(false);
        }




        if (SignUpPassword.length <= maxPasswordLength) {
            setSignUpPassword(SignUpPassword);
        }

        if (
            SignUpPassword.length < 6 ||
            SignUpPassword.length > maxPasswordLength
        ) {
            setSignupPasswordFlag(false);
            setSignupPasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters");
            setShowSignUpButtonLoader(false);
            // return;
        }

        if (RetypeSignUpPassword.length <= maxPasswordLength) {
            setRetypeSignUpPassword(RetypeSignUpPassword);
        }

        if (
            RetypeSignUpPassword.length < 6 ||
            RetypeSignUpPassword.length > maxPasswordLength
        ) {
            setRetypeSignupPasswordFlag(false);
            setRetypeSignupPasswordError(
                "Password should be between 6 to " + maxPasswordLength + " characters"
            );
            setShowSignUpButtonLoader(false);
            // return;
        }

        if (!FirstName || !LastName || !SignUpEmail || !SignUpPassword || !SignUpMobileNo || !RetypeSignUpPassword) {
            toast.warn('Please fill in all required fields', {
                toastid: customId,
                position: 'top-right',
                autoClose: 2000,
            })
            return;
        }

        if (!FirstNameFlag) {
            toast.warn("Please enter first name", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSignUpButtonLoader(false)
            return;
        }
        if (!LastNameFlag) {
            toast.warn("Please enter last name", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSignUpButtonLoader(false)
            return;
        }

        if (!SignupEmailFlag) {
            toast.warn("Please enter valid email address", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSignUpButtonLoader(false)
            return;
        }

        if (!SignupPasswordFlag) {
            toast.warn("Please enter password.   ", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSignUpButtonLoader(false)
            return;
        }

        if (SignUpPassword != RetypeSignUpPassword) {
            toast.warn("The password and re-entered password do not match.", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSignUpButtonLoader(false)
            return;
        }

        if (!RetypeSignupPasswordFlag) {
            toast.warn("Please enter Retype  password", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSignUpButtonLoader(false)
            return;
        }
        if (SignUpMobileNo.length <= 11) {
            toast.warn('Please enter valid phone number', {
                position: 'top-right',
                autoClose: 2000,
            })
            setShowSignUpButtonLoader(false)
            return;
        }

        const result = await SignupApi(FirstName, LastName, SignUpEmail, SignUpPassword, SignUpMobileNo);
        localStorage.setItem("SignUpEmailForOtpVerify", SignUpEmail);

        if (result.status === "1") {
            setOpenVerifyEmailModal(true);
            setShowSignUpButtonLoader(false)
            toast.success(result.message, {
                toastId: customId,
                position: "top-right",
                autoClose: 2000,
            })
            // console.log(result)
            return;
        } else {
            toast.error(result.message, {
                toastId: customId,
                position: "top-right",
                autoClose: 2000,
            })
            setShowSignUpButtonLoader(false)
        }

    }



    

    // variable for otp length and opt value 

    const HandleVerifyEmail = async () => {
        setShowSubmitOtpLoader(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if (!VerifyOtp) {
            toast.warn("Please enter the OTP.", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSubmitOtpLoader(false)
            return;
        }

        if (VerifyOtp.length < 6 || VerifyOtp.length > MaxOTPLength) {
            toast.warn('OTP length should be 6 digits', {
                position: 'top-right',
                autoClose: 2000
            })
            setShowSubmitOtpLoader(false)
            return;
        }

        

        setShowSubmitOtpLoader(true)
        if (!VerifyOtp) {
            toast.warn("Please enter the OTP.", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSubmitOtpLoader(false)
            return;
        }

        if (VerifyOtp.length < 6 || VerifyOtp.length > MaxOTPLength) {
            toast.warn('OTP length should be 6 digits', {
                position: 'top-right',
                autoClose: 2000
            })
            return;
        }

        const result = await VerifyEmail(SignUpEmailForOtpVerify, VerifyOtp);
        if (result.status === "1") {
            toast.success(result.message, {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            localStorage.removeItem("SignUpEmailForOtpVerify");
            setShowSubmitOtpLoader(false)
            setOpenVerifyEmailModal(false);
            navigate("/login");
            setVerifyOtp("");
        } else {
            toast.error(result.message, {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            setShowSubmitOtpLoader(false)
            setVerifyOtp("");
            return;
        }

    }

    const MaxOTPLength = 6

    const handleVerifyOTP = (e) => {
        const OTPValue = e.target.value;

        if (OTPValue.length <= MaxOTPLength) {
            setVerifyOtp(OTPValue);
        }

    }


    const HandleResendOtp = async () => {
        const result = await ResendOtp(SignUpEmailForOtpVerify);
        if (result.status === "1") {
            toast.success(result.message, {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
        }
        else {
            toast.error(result.message, {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return
        }


    }


    

    return (
        <>

            <div className="SignUp">
                <div className="Box_1">
                    {/* <SignUpAnimate /> */}
                </div>

                <div className="Box_2">
                    <div id="LoginTitle">
                        <H1>Sign Up </H1>
                        <img src={StarteleLogo} />
                    </div>

                    <form id="login_data">
                        <div>
                            <TextField
                                fullWidth
                                label={
                                    <span>
                                        First Name<span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                variant="outlined"
                                inputProps={{ tabIndex: "1" }}
                                value={FirstName}
                                onChange={handleFirstName}
                                autoComplete="off"
                            // style={{border: !FullNameFlag ? '1px solid red': ''}}
                            />
                            {FirstNameError && (
                                <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{FirstNameError}</p>
                            )}
                        </div>

                        <div>
                            <TextField
                                fullWidth
                                label={
                                    <span>
                                        Last Name<span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                variant="outlined"
                                inputProps={{ tabIndex: "2" }}
                                value={LastName}
                                onChange={handleLastName}
                                autoComplete="off"
                            // style={{border: !FullNameFlag ? '1px solid red': ''}}
                            />
                            {LastNameError && (
                                <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{LastNameError}</p>
                            )}
                        </div>

                        <div>
                            <TextField
                                fullWidth
                                label={
                                    <span>
                                        Email Id<span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                variant="outlined"
                                inputProps={{ tabIndex: "3" }}
                                value={SignUpEmail}
                                onChange={handleSignUpEmailChange}
                                autoComplete="off"
                                style={{ color: "#666666" }}
                            />
                            {SignupEmailError && (
                                <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{SignupEmailError}</p>
                            )}
                        </div>

                        <>


                            <PhoneInput
                                country={"in"}
                                enableSearch={true}
                                inputProps={{ tabIndex: "4" }}
                                value={SignUpMobileNo}
                                onChange={handleMobileChange}
                                style={{
                                    width: "100%",
                                }}
                                inputStyle={{
                                    background: "transparent",
                                    width: "100%",
                                    height: '54px',
                                    border: '1px solid gray'
                                }}
                            />
                            {MobileNoError && (
                                <p style={{ color: "red", fontSize: "13px", }}>{MobileNoError}</p>
                            )}
                        </>

                        <div>
                            <div style={{ position: "relative", width: "100%", }} >
                                <TextField
                                    fullWidth
                                    type={ShowSigninPassword ? "text" : "password"}
                                    label={<span>Password<span style={{ color: 'red' }}> *</span></span>}
                                    variant="outlined"
                                    inputProps={{ tabIndex: "5" }}
                                    value={SignUpPassword}
                                    autoComplete="off"
                                    onChange={handleSignUpPassword}
                                />
                                <span
                                    onClick={() =>
                                        togglePasswordVisibility(
                                            ShowSigninPassword,
                                            setShowSigninPassword
                                        )
                                    }
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: "40%",
                                        right: "5%",
                                    }}
                                >
                                    {ShowSigninPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                            {SignupPasswordError && (
                                <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{SignupPasswordError}</p>
                            )}
                        </div>

                        <div>
                            <div style={{ position: "relative", width: "100%", }} >
                                <TextField
                                    fullWidth
                                    type={ShowRetypePassword ? "text" : "password"}
                                    label={<span>Retype Password<span style={{ color: 'red' }}> *</span></span>}
                                    variant="outlined"
                                    inputProps={{ tabIndex: "6" }}
                                    value={RetypeSignUpPassword}
                                    autoComplete="off"
                                    onChange={handleRetypeSignupPassword}
                                />
                                <span
                                    onClick={() =>
                                        togglePasswordVisibility(
                                            ShowRetypePassword,
                                            setShowRetypePassword
                                        )
                                    }
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: "40%",
                                        right: "5%",
                                    }}
                                >
                                    {ShowRetypePassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                            {RetypeSignupPasswordError && (
                                <p style={{ color: "red", fontSize: "13px", marginTop: '10px' }}>{RetypeSignupPasswordError}</p>
                            )}
                        </div>



                        {/* <FormControl
                            component="fieldset"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={PromotionMail}
                                        onChange={() => setPromotionMail(!PromotionMail)}
                                    />
                                }
                                label={
                                    <Typography
                                        style={{
                                            fontSize: "17px",
                                            color: "black",
                                            fontWeight: "300",
                                        }}
                                    >
                                        I'd like to recieve promotional emails.
                                    </Typography>
                                }
                            />
                        </FormControl> */}
                    </form>

                    <Button
                        onClick={HandleSignUp}
                        tabIndex={6}
                        // type="submit"
                        style={{
                            width: "90%",
                            fontSize: "15px",
                            color: "white",
                            backgroundColor: "#176D96",
                            borderRadius: "5px",
                            margin: "20px 0px ",
                        }}
                    >
                        {ShowSignUpButtonLoader ?
                            <RotatingLines
                                visible={true}
                                height="26"
                                width="26"
                                color="white"
                                strokeWidth="5"
                                animationDuration="1"
                                strokeColor='white'
                            /> : "SIGN UP"
                        }
                    </Button>

                    <p
                        style={{
                            fontSize: "15px",
                            fontWeight: "300",
                            // letterSpacing: ".5px",
                            lineHeight: "20px",
                            margin: "10px",
                            textAlign: "justify",
                        }}
                    >
                        By signing up for an startele account, I agree to the{" "}
                        <span style={{ fontWeight: "400", textDecoration: "underline" }}>
                            {" "}
                            Terms & Conditions.
                        </span>{" "}
                        Learn more about how we use and protect your data in our{" "}
                        <span style={{ fontWeight: "400", textDecoration: "underline" }}>
                            Privacy Policy.
                        </span>
                    </p>
                </div >

                {/* Modal for veryfing the OTP Mail */}
                < Modal open={OpenVerifyEmailModal} >
                    <Box sx={ModalStyle2}>
                        <div
                            className="box2_1"
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <H2
                                style={{
                                    fontSize: "16px",
                                    width: "100%",
                                    fontWeight: "300",
                                    margin: "10px 0px",
                                }}
                            >
                                A Verificatioin Email with OTP has been sent to your
                                <span style={{ color: "#E25141", textDecoration: "underline" }}>
                                    {" "}
                                    {SignUpEmailForOtpVerify}{" "}
                                </span>{" "}
                                ,
                                <br /> Please Verify it.
                            </H2>

                            <div
                                className="inputInfoBox "
                                style={{ width: "85%", marginBottom: "20px" }}
                            >
                                <OutlinedInput
                                    autoFocus={true}
                                    type="text"
                                    inputProps={{ tabIndex: "1" }}
                                    value={VerifyOtp}
                                    // onChange={(e) => setVerifyOtp(e.target.value)}
                                    onChange={handleVerifyOTP}
                                    style={{ width: "100%", height: "40px" }}
                                    placeholder="Enter the OTP here."

                                />

                                <H2
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "200",
                                        marginTop: "10px",
                                    }}
                                >

                                </H2>
                                <p style={{ display: 'flex', width: 'fit-content',  padding: '0px px',  }}><span>If you haven't received the OTP, click on</span>
                                    <span onClick={HandleResendOtp} style={{ marginLeft: '5px', textDecoration: 'underline', color: 'red', cursor: 'pointer' }}> Resend OTP </span>
                                </p>
                            </div>



                            <div>
                                <Button
                                    onClick={HandleVerifyEmail}
                                    style={{
                                        color: "green",
                                        marginRight: "20px",
                                    }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    {ShowSubmitOtpLoader ?
                                        <RotatingLines
                                            visible={true}
                                            height="26"
                                            width="26"
                                            color="white"
                                            strokeWidth="5"
                                            animationDuration="1"
                                            strokeColor='green'
                                        /> : "Submit"
                                    }
                                </Button>

                                <Button
                                    onClick={() => {
                                        setOpenVerifyEmailModal(false);
                                        setVerifyOtp("");
                                    }}
                                    style={{ color: "indianRed" }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Modal >
            </div >

        </>
    )
}


export default SignUp;