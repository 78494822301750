import React, { useEffect } from 'react';
import './OTPValidation.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import OTPValidationApiReference from './SwaggerApiRef/OTPValidationApiReference';
import Footer from '../../Footer/Footer';


const OTPValidation = () => {
    const [TabValue, setTabValue] = useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // For open the page to the top 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Box className='OTPValidationBox' sx={{ typography: 'body1' }}>
            <div className='OTPValidationHeading'>
                <h1>OTP Validation</h1>
            </div>
            <div className='OTPValidationTabDetails'>
                <TabContext value={TabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Overview" value="1" />
                            <Tab label="API Reference" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div className='OverviewDetails'>
                            <p className='main-heading'> OTP Validation <span style={{ fontSize: '18px', fontWeight: '500', }}>API is part of</span> <span id='ApiDocs' style={{ textDecoration: 'underline' }}>startelelogic APIs.</span></p>
                            <p>The purpose of this sandbox version is to verify one-time passwords (OTPs) that the system generates.</p>
                            <h3>What it does?</h3>
                            <p>The OTP Validation API ensures safe and dependable user authentication procedures by instantly confirming the authenticity of a provided OTP.</p>
                            <p>Using this API helps in preventing unauthorized access to user accounts and ensures that the OTP is valid and hasn't expired.</p>
                            <p>The OTP Validation API offers resource management in response to several inquiries:</p>
                            <ul>
                                <li>Is the provided OTP valid?</li>
                                <li>Has the OTP expired?</li>
                            </ul>
                        </div>
                    </TabPanel>

                    {/* Import the ApiReference Page  */}
                    <TabPanel value="3"><OTPValidationApiReference /></TabPanel>

                </TabContext>
            </div>
          
        </Box>
          <Footer />
          </>
    );
};

export default OTPValidation;
