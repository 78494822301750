import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faSimCard, faCheckCircle, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import "./CardSection.css"; // Custom CSS for styling
import { useNavigate } from "react-router-dom";


const CardSection = () => {
  const navigate = useNavigate()

;

  const handleSetProductName = (productTitle) => {
    localStorage.setItem("APIName", productTitle)
    navigate('/plans')
  }


  const cards = [
    {
      title: "GET-DEVICE-LOCATION",
      icon: faMapMarkerAlt,
      description: "Track the real-time location of your device for enhanced security.",
      badge: "Exclusive Offer",
    },
    {
      title: "SIM SWAP",
      icon: faSimCard,
      description: "Detect and prevent unauthorized SIM swaps to protect your account.",
      badge: "Exclusive Offer",
    },
    {
      title: "DEVICE LOCATION VERIFICATION",
      icon: faCheckCircle,
      description: "To verify device location ",
      badge: "Exclusive Offer",
    },
    {
      title: "OTP-VALIDATION",
      icon: faShieldAlt,
      description: "Ensure secure transactions with real-time OTP validation.",
      badge: "Exclusive Offer",
    },
  ];

  return (
    <div className="card-section-wrapper">
      <div className="section-heading">  <h2>Pick the Perfect <span>Plans </span>for Your Vision</h2>
      </div>
      <div className="card-section">
        {cards.map((card, index) => (
          <>
            <div className="plan_card" onClick={() => handleSetProductName(card.title)}>
              <div key={index} className="card" >

                <div className="card-icon">
                  <FontAwesomeIcon icon={card.icon} size="4x" color="#5A67D8" />
                </div>
                <h3 className="card-title">{card.title}</h3>
                <p className="card-description">{card.description}</p>
                {card.badge && <div className="card-badge">{card.badge}</div>}
              </div>
              <button className="card-button">Click to buy our exclusive plans </button>
            </div>

          </>
        ))}
      </div>
    </div>

  );
};

export default CardSection;
