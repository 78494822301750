import React, { useState, useEffect } from 'react'
import './UserProfile.css'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Divider } from '@mui/material';
import { ContactPageOutlined, ContactPhone, Verified } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import TokenIcon from '@mui/icons-material/Token';
import KeyIcon from '@mui/icons-material/Key';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ContentCopy from "@mui/icons-material/ContentCopy";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Card, CardContent, CardActions, Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Tag } from '@mui/icons-material';
//These imports are for the reports download
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';
import Footer from '../../Components/Footer/Footer'
import { Bars } from 'react-loader-spinner';



const UserProfile = () => {
  const [AllDataResponse, setALLDataResponse] = useState('')
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const FirstName = localStorage.getItem('FirstName');
  const LastName = localStorage.getItem('LastName');
  const AuthToken = localStorage.getItem('AuthToken'); // for getting the auth token from local storage
  const EmailId = localStorage.getItem('EmailId');
  const PhoneNumber = localStorage.getItem('PhoneNumber');
  const EmailStatus = localStorage.getItem('EmailStatus')
  const APIKey = localStorage.getItem('APIKey')
  // console.log(APIKey, "--------------------");


  const [paymentHistory, setPaymentHistory] = useState([]);

  const customId = "forNotShowingMultipleToast"

  const firstName = 'Prashant';
  const lastName = 'kumar'
  const [value, setValue] = useState('1');

  const initials = `${FirstName[0]}${LastName[0]}`.toUpperCase();

  const [isVisible, setIsVisible] = useState(false);


  const token = AuthToken;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(APIKey);
    toast.success('API Key copied successfully', {
      toastid: customId,
      position: 'top-right',
      autoClose: 2000,
    })
  };



  const ShowPaymentHistory = async () => {
    const myHeaders = new Headers();
    myHeaders.append("X-API-Key", APIKey);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${REACT_APP_BASE_URL}/payment/plan/get-payment-details`, requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      setALLDataResponse(result.status)
      setPaymentHistory(result.data || []);
    } catch (error) {
      console.error('Fetch operation failed:', error.message);
    }
  };








  useEffect(() => {
    ShowPaymentHistory()
  }, [])


  const formatISOToLocal = (isoDate) => {
    if (!isoDate) return '--';
    const date = new Date(isoDate);
    return date.toLocaleString();
  };

  const generatePDF = (cardId) => {
    const cardElement = document.getElementById(cardId); // Find the specific card
    html2canvas(cardElement, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a6'); // Create a PDF instance
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Add the image to the PDF
      pdf.save('payment_card.pdf'); // Download the PDF
    });
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>

      <div className="profile-container">
        <div className="user-profile">
          <div className="user-profile-heading">
            <h2>User Profile Setting <SettingsSuggestIcon fontSize='24px' /></h2>
          </div>

          <div className="tab-container">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="user profile setting option">
                    <Tab label="Profile Details" value="1" />
                    {/* <Tab label="Password Change" value="2" /> */}

                  </TabList>
                </Box>
                <TabPanel value="1">


                  <div className="personal-detail">
                    <div className="personal-detail-container">
                      <div className="dp-container">
                        <div className="circle-dp">
                          <span>{initials}</span>
                        </div>
                      </div>

                      <div className="detail">
                        <div className="UserInfo-header-container">
                          <h2 className='UserInfo-detail-heading'>User Info  <PersonIcon fontSize='20px' />  </h2>
                        </div>
                        <Divider />
                        <div className="names">
                          <div className="firstName">First Name : <span>{FirstName} </span></div>
                          <div className="lastName">Last Name : <span>{LastName} </span></div>
                          {/* <div className="fullName">Full Name : <span> Prashant kumar </span></div> */}
                        </div>

                        <div className="contact">

                          <div className="contact-header-container">
                            <h2 className='contact-detail-heading'>Contact  <ContactPageOutlined fontSize='20px' />  </h2>
                          </div>
                          <Divider />

                          < div className="mobile-email">
                            <div className="email">Email : <span>{EmailId}</span></div>
                            <div className="mobile">Mobile : <span>{PhoneNumber} </span></div>
                            <div className="status">Email Status: <span> {EmailStatus === "true" ? <span style={{ color: '#353434', fontSize: '20px', fontWeight: "300", display: 'inline-flex', alignItems: 'center' }}>verified <CheckCircleIcon sx={{ color: 'green', marginLeft: '5px' }} /> </span> : "Not-verified"} </span></div>
                          </div>

                        </div>
                        <div className="token">

                          <div className="token-header-container">
                            <h2 className='token-detail-heading'>API Key  <KeyIcon fontSize='20px' />  </h2>
                          </div>
                          <Divider />

                          <div className="token-container">
                            <div className="token-display" style={{ overflow: 'auto' }} >
                              {isVisible ? APIKey : '••••••••••••••••••••••••••••••'}
                            </div>
                            <div className='Apikey_btn'>


                              <button className="toggle-button" onClick={() => setIsVisible(!isVisible)} style={{ fontSize: '25px' }} >
                                {isVisible ? <FaEye /> : <FaEyeSlash />}
                              </button>
                              <button className="copy-button" onClick={copyToClipboard}>
                                <ContentCopy />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                </TabPanel>
                <TabPanel value="2">Item Two</TabPanel>

              </TabContext>
            </Box>
          </div>
        </div>


      </div>

      <Box className="payment-card-box" sx={{
        width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'
      }}>
        {/* Tabs for navigation */}
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Payments" />
          {/* <Tab label="plan" />   */}
          {/* <Tab label="Password" /> */}
        </Tabs>

        {/* Tab Panel 1: Plan */}
        {tabValue === 0 && (
          <Box className="payment-box"

          >
            {AllDataResponse === 1 ? <>
              {paymentHistory.length === 0 ? (
                <Typography color="black" align="center" sx={{ gridColumn: 'span 3' }}>
                  No payment history available.
                </Typography>
              ) : (
                paymentHistory.map((payment, index) => (
                  <Card
                    id={`card-${index}`} // Assign a unique id to each card
                    key={payment.plan_name}
                    sx={{
                      padding: '20px',
                      backgroundColor: '#ffffff',
                      borderRadius: '16px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      textAlign: 'center', // Center align card content
                    }}
                  >
                    {/* Success Icon */}
                    <CheckCircleIcon
                      sx={{
                        fontSize: 50,
                        color: 'green',
                        marginBottom: '10px',
                      }}
                    />
                    <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      Payment Success!
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '15px' }}>
                      Your payment has been successfully done.
                    </Typography>

                    <Typography
                      variant="h6"
                      sx={{
                        marginTop: '10px',
                        marginBottom: '20px',
                        fontWeight: 'bold',
                        color: '#333',
                      }}
                    >
                      Total Payment
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{ color: '#1976d2', fontWeight: 'bold', marginBottom: '20px' }}
                    >
                      ${payment.price}
                    </Typography>

                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '10px',
                        textAlign: 'left',
                        marginBottom: '20px',
                      }}
                    >
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Plan Name:
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {payment.plan_name || '--'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Payment Time:
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {formatISOToLocal(payment.created_at)}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Plan Category:
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {payment.plan_category || '--'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          API Name:
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {payment.api_name || '--'}
                        </Typography>
                      </Box>
                    </Box>

                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: '#1976d2',
                        color: '#1976d2',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: '#f0f7ff' },
                      }}
                      onClick={() => generatePDF(`card-${index}`)} // Pass the card ID to the function
                    >
                      Get PDF
                    </Button>
                  </Card>
                ))
              )}
            </> : <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '80vw', }}>
              <Bars
                height="40"
                width="40"
                color="rgb(76,114,175)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
            </>}

          </Box>


        )}

        {/* Tab Panel 2: Payments */}
        {tabValue === 1 && (
          <Box sx={{ p: 2 }}>
            <Typography>Payment history and details will go here.</Typography>
          </Box>
        )}

      </Box>
      <Footer />
    </>
  )
}

export default UserProfile