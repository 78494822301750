import React, { useEffect, useState } from 'react';
import './Login.css';
import { LoginApi } from '../../APIs/Api';


import StarteleLogo from '../../../Assets/Star.png'
// import { useAuth } from '../../../AuthContext'; // Update the import path accordingly

import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { H2, H1, H3 } from "../../Typography";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
    styled,
    Button,
    FormControl,
    Switch,
    FormGroup,
    FormControlLabel, Radio,
    TextField,
    Checkbox,
    Box,
    OutlinedInput,
    withTheme,
} from '@mui/material';
import { Label } from '@mui/icons-material';
import { Bounce, Flip, Slide, toast, Zoom } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Modal from "@mui/material/Modal";
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Bars, ColorRing, FallingLines, Hourglass, RotatingLines } from 'react-loader-spinner';
import { useAuth } from '../../customHook/AuthContext';


const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "10px",
    border: "0px",
    p: 3,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#107a02d8",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#afafd1" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));




const Login = () => {
   
    const navigate = useNavigate()

    const customId = "forNotShowingMultipleToast"


    // For the Login Page States
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');
    const [emailFlag, setEmailFlag] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [Remember, setRemember] = useState(false)
    const [ShowLoginPassword, setShowLoginPassword] = useState(false);

    const [ShowLoginButtonLoader, setShowLoginButtonLoader] = useState(false)
    const [ShowSubmitOtpLoader, setShowSubmitOtpLoader] = useState(false)



    // Login page validation start here
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);

    }

    const validateEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!email) {
            setEmailError("Please enter your email id");
            setEmailFlag(false);
        } else if (!emailPattern.test(email)) {
            setEmailError("Invalid email address");
            setEmailFlag(false);
        } else {
            setEmailError("");  // Clear any previous error
            setEmailFlag(true); // Set flag to true for valid email
        }
    }

    const validatePassword = (password) => {
        if (!password) {
            setPasswordError("Please Enter Your Password");
        } else {
            setPasswordError("");
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const togglePasswordVisibility = (value, SetValue) => {
        SetValue(!value);
    };



    const HandleLogin = async (event) => {
        event.preventDefault();

        setShowLoginButtonLoader(true);

        // Make sure email and password are populated
        if (!email || !password) {
            toast.warn('Please fill in all required fields', {
                toastId: customId,
                position: 'top-right',
                autoClose: 2000
            });
            setShowLoginButtonLoader(false);
            return;
        }

        const result = await LoginApi(email, password);



        if (result.status === "1") {
            if (result?.accounts?.role !== "user") {
                toast.error("Only User can login", {
                    position: 'top-right',
                    autoClose: 2000
                });
                setShowLoginButtonLoader(false);
                return;
            }
            toast.success(result.message, {
                position: 'top-right',
                autoClose: 2000
            });

            navigate('/');
            
           
            setShowLoginButtonLoader(false);
            localStorage.setItem('isLoggedIn', "true");
            localStorage.setItem('FirstName', result.accounts.first_name)
            localStorage.setItem('LastName', result.accounts.last_name)
            localStorage.setItem('EmailId', result.accounts.email_id)
            localStorage.setItem('UserUUId', result.accounts.user_uuid);
            localStorage.setItem('AuthToken', result.Auth_token);
            localStorage.setItem('PhoneNumber', result.accounts.mobile_number);
            localStorage.setItem('EmailStatus', result.accounts.email_verified);
            localStorage.setItem('APIKey', result.accounts.api_key);
        } else {
            toast.error(result.message, {
                position: 'top-right',
                autoClose: 2000
            });
            setShowLoginButtonLoader(false);
        }

    }

    return (
        <>
            <div className='Login'>
                <Box component="form" onSubmit={HandleLogin} className='Box_23' >
                    <div id='LoginTitle' >
                        <H1>Login </H1>
                        <img src={StarteleLogo} />
                    </div>

                    <div id="login_data" >

                        <TextField
                            label="Email Id"
                            variant="outlined"
                            autoComplete='off'
                            inputProps={{ tabIndex: "1" }}
                            value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            onChange={handleEmailChange}
                            style={{ margin: '5px 0px' }}
                        />
                        {emailError && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                                {emailError}
                            </div>
                        )}



                        <div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    type={ShowLoginPassword ? "text" : "password"}
                                    label="Password"
                                    variant="outlined"
                                    autoComplete='off'
                                    inputProps={{ tabIndex: "2" }}
                                    value={password}
                                    // onChange={(e) => setPassword(e.target.value)}
                                    onChange={handlePasswordChange}
                                    style={{ margin: '20px 0px 5px 0px' }}
                                />

                                <span
                                    onClick={() => togglePasswordVisibility(ShowLoginPassword, setShowLoginPassword)}
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: "45%",
                                        right: "5%",
                                        fontSize: '20px',
                                        color: '#4B64FF'
                                    }}
                                >
                                    {ShowLoginPassword ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                            {passwordError && (
                                <p style={{ color: "red", fontSize: "14px", }}>
                                    {passwordError}
                                </p>
                            )}


                        </div>

                        <div className='remember-forget' style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '10px' }} >
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                name="Remember Me"
                                                checked={Remember}
                                                onChange={() => {
                                                    setRemember(!Remember);
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography className='remember-me-text' style={{ fontSize: '15px', marginLeft: '10px' }}>
                                                Remember Me
                                            </Typography>
                                        } />
                                </FormGroup>
                            </FormControl>

                            <p className='forget-text' style={{
                                color: "#176D96",
                                fontSize: "18px",
                                textDecoration: "underline",
                                cursor: 'pointer'
                            }}
                                onClick={() => { navigate('/forgotPassword') }}
                            >
                                Forgot password?
                            </p>
                        </div>
                    </div>


                    <Button
                        onClick={HandleLogin}
                        type="submit"
                        tabIndex={3}
                        style={{
                            width: "90%",
                            fontSize: "15px",
                            color: "white",
                            // border: "1px solid #011015",
                            backgroundColor: "#176D96",
                            borderRadius: "5px",
                            marginTop: '10px',

                        }}
                    >

                        {ShowLoginButtonLoader ?
                            <RotatingLines
                                visible={true}
                                height="26"
                                width="26"
                                color="white"
                                strokeWidth="5"
                                animationDuration="1"
                                strokeColor='white'
                            /> : "Log In"
                        }
                    </Button>






                </Box>
            </div>
        </>
    )

}


export default Login;

