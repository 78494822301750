import React, { useState, useEffect, useRef } from 'react';
import './ApiDocumentation.css';
import { Psychology, QueryStats } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Filter1Icon from '@mui/icons-material/Filter1';  
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faBoxOpen, faKey, faPlayCircle } from '@fortawesome/free-solid-svg-icons';

// import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';

import productSelectionImg from '../../../Assets/guideSteps/productSelectionImg.png'
import signUpImage from '../../../Assets/guideSteps/signUp.png';
import tokenImg from '../../../Assets/guideSteps/tokenImg.png';
import useApiImg from '../../../Assets/guideSteps/useApi.png'


const ApiDocumentation = () => {
  const [activeSection, setActiveSection] = useState('');
  const sectionsRef = useRef([]);

  const handleLinkClick = (section) => {
    setActiveSection(section);
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0.4,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    // Observe each section
    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      // Cleanup observer on component unmount
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <div className="api-doc-page">
      {/* Hero Section */}
      <div className="api-hero-container">
        <p className="api-hero-title">Welcome to <span style={{ color: "rgb(68, 100, 240)", fontSize: "clamp(1rem, 2vw + 1rem, 2rem)" }}> startelelogic  </span> portal ! Here we  guide for smooth Integration</p>
        <p className="api-hero-subtitle">Unlock the potential of our APIs with this detailed and developer-friendly guide. Inside, you’ll find everything you need to get started</p>
      </div>

      <div className="api-content-wrapper">

        {/* Table of Contents */}
        <div className="api-table-of-contents">
          <h3>Table of Contents</h3>
          <ul>
            <li>
              <a
                href="#introduction"
                onClick={() => handleLinkClick('introduction')}
                className={activeSection === 'introduction' ? 'active-link' : ''}
              >
                Introduction
              </a>
            </li>
            <li>
              <a
                href="#what-we-provide"
                onClick={() => handleLinkClick('what-we-provide')}
                className={activeSection === 'what-we-provide' ? 'active-link' : ''}
              >
                What we provide
              </a>
            </li>

            {/* <li>
              <a
                href="#how-to-use"
                onClick={() => handleLinkClick('how-to-use')}
                className={activeSection === 'how-to-use' ? 'active-link' : ''}
              >
                How to use
              </a>
            </li> */}
            <li>
              <a
                href="#stepsToUse"
                onClick={() => setActiveSection('stepsToUse')}
                className={activeSection === 'stepsToUse' ? "active-link" : ''}
              >
                Steps to Use
              </a>
            </li>

            {/* steps list */}
            <ul>
              <li>
                <a
                  href="#guide-sign-Up"
                  onClick={() => handleLinkClick('guide-sign-Up')}
                  className={activeSection === 'guide-sign-Up' ? 'active-link' : ''}
                >
                  Sign Up
                </a>
              </li>

              <li>
                <a
                  href="#goToProduct"
                  onClick={() => handleLinkClick('goToProduct')}
                  className={activeSection === 'goToProduct' ? 'active-link' : ''}
                >
                  Go to Product
                </a>
              </li>
              {/* <li>
                <a
                  href="#authorizeWithToken"
                  onClick={() => handleLinkClick('authorizeWithToken')}
                  className={activeSection === 'authorizeWithToken' ? 'active-link' : ''}
                >
                  Authorize with Token
                </a>
              </li> */}
              <li>
                <a
                  href="#useAPI"
                  onClick={() => handleLinkClick('useAPI')}
                  className={activeSection === 'useAPI' ? 'active-link' : ''}
                >
                  Use the API
                </a>
              </li>

            </ul>

            <li>
              <a
                href="#conclusion"
                onClick={() => handleLinkClick('conclusion')}
                className={activeSection === 'conclusion' ? 'active-link' : ''}
              >
                Conclusion
              </a>
            </li>


          </ul>
        </div>

        {/* Content Section */}
        <div className="api-content-section">
          <h2 className="api-section-title"> Overview</h2>

          {/* introduction Section */}
          <section className="api-section" id="introduction" ref={(el) => sectionsRef.current.push(el)}>
            <h3 className="api-subtitle">Introduction <QueryStats /></h3>
            <p className="api-description">
              At startelelogic, we’re driven by innovation and the spirit of collaboration. Our goal is to equip developers with the tools they need to create digital experiences that positively impact our customers. Our Portal opens the door to endless opportunities, providing all the resources and support you need to transform your ideas into reality.
            </p>

          </section>

          {/* What we provide Section */}
          <section className="api-section" id="what-we-provide" ref={(el) => sectionsRef.current.push(el)}>
            <h3 className="api-subtitle"> What we provide <FormatListBulletedIcon /></h3>
            <p className="api-description">
              startelelogic simplifies telco networks with APIs that work seamlessly across different networks and countries. This ensures easy access and a more efficient user experience.
            </p>  
            <div className="api-product">
              <p className="api-header">Our product includes the following APIs:</p>
              <ul className="api-list">
                <li className="api-item">
                  <span>Device Location:</span> This sandboxed version of the API is restricted to a predefined set of mobile devices, identified by their MSISDNs (Mobile Station International Subscriber Directory Numbers).
                </li>

                <li className="api-item" >
                  <span>SIM SWAP:</span> Users can check if a SIM swap has been finished on a particular mobile line using the SIM Swap API. It checks in real time whether a mobile number (MSISDN) has been moved to a different SIM card..
                </li>
                <li className="api-item">
                  <span>Device Location Verification:</span> Developers may monitor device status in real-time and obtain vital information to improve operational efficiency using the Device Location Verification API.
                </li>

                <li className="api-item">
                  <span>OTP Status:</span> The OTP Validation API provides a programmatic interface that allows developers to confirm the authenticity of one-time passwords (OTPs) issued by the system.
                </li>
              </ul>
            </div>


          </section>

          {/*How to Use   */}
          <section className="api-section" id="stepsToUse"
           ref={(el) => sectionsRef.current.push(el)}
           >
            <h3 className="api-subtitle">Step to Use < WysiwygIcon /></h3>
            <p className="api-description">
              Sign up for an account to gain access to the API portal. Once registered, navigate to the product section to explore the available APIs. Review the different APIs and their functionalities to find what you need.</p>
            <p className="api-description"> Authenticate your access using your API key or token for secure entry. Start making API requests to interact with the APIs and retrieve data. Integrate the API responses into your project for enhanced functionality.
            </p>
            <div className="stepper-container" id='stepsToUse'>
              <h3 className="stepper-title">Steps to Use Our Product API</h3>
              <div className="stepper">
                {/* Step 1: Sign Up */}
                <div className="step" >
                  <a href='#guide-sign-Up'>
                    <div className="step-icon">

                      <FontAwesomeIcon icon={faSignInAlt} />
                    </div>
                    <div className="step-content">
                      <h4>Sign Up</h4>
                      <p>Sign Up to your account to access the API portal.</p>

                    </div>
                  </a>
                </div>

                {/* Step 2: Go to Product */}
                <div className="step">
                  <a href='#goToProduct'>
                    <div className="step-icon">
                      <FontAwesomeIcon icon={faBoxOpen} />
                    </div>
                    <div className="step-content">
                      <h4>Go to Product</h4>
                      <p>Navigate to the product section to explore available APIs.</p>
                    </div>
                  </a>
                </div>

                {/* Step 3: Authorize with Token */}
                {/* <div className="step" onClick={() => '#guide-sign-up'}>
                  <a href='#authorizeWithToken'>
                    <div className="step-icon">
                      <FontAwesomeIcon icon={faKey} />
                    </div>
                    <div className="step-content">
                      <h4>Authorize with Token</h4>
                      <p>Authenticate using your API key or token to gain access.</p>
                    </div>
                  </a>
                </div> */}

                {/* Step 4: Use */}
                <div className="step">
                  <a href='#useAPI'>
                    <div className="step-icon">
                      <FontAwesomeIcon icon={faPlayCircle} />
                    </div>
                    <div className="step-content">
                      <h4>Use</h4>
                      <p>Start making API requests and integrating them into your project.</p>
                    </div>
                  </a>
                </div>

              </div>
            </div>

          </section>

          {/* Step 1:Login */}
          <section className="api-section" id="guide-sign-Up" ref={(el) => sectionsRef.current.push(el)}>
            <h3 className="api-subtitle">
              <Filter1Icon /> Sign Up
            </h3>
            <p className="api-description">
              Sign Up to access the API portal so you can securely manage your API interactions, monitor usage, and retrieve necessary credentials for API requests.</p>
            <p className="api-description"> Signing in ensures secure access to personalized resources and usage insights tailored to your account.
            </p>

            {/* Image for SignUp Step */}
            <div className="step-image-container">
              <img src={signUpImage} alt="Step 1: Login Screenshot" className="step-image" />
            </div>
          </section>

          {/* Step 2: Go to Product */}
          <section className="api-section" id="goToProduct" ref={(el) => sectionsRef.current.push(el)}>
            <h3 className="api-subtitle">
              <Filter2Icon /> Go to Product
            </h3>
            <p className="api-description">
              Visit the product section to uncover the range of available APIs.
              Examine their functionalities to see how they can serve your needs.
            </p>
            <p className="api-description">
              Each API comes with detailed documentation for better understanding.
              Select the API that aligns perfectly with your development goals.
            </p>

            {/* Image for Go to Product Step */}
            <div className="step-image-container">
              <img src={productSelectionImg} alt="Step 2: Go to Product Screenshot" className="step-image" />
            </div>


          </section>

          {/* Step 3: Authorize with Token */}
          {/* <section className="api-section" id="authorizeWithToken" ref={(el) => sectionsRef.current.push(el)}>
            <h3 className="api-subtitle">
              <Filter3Icon /> Authorize with Token
            </h3>
            <p className="api-description">
              Use the provided authorization token to gain access to the API. The token ensures secure and authenticated communication between your app and the API.
            </p>
            <p className="api-description">
              The token should be included in the request headers to authenticate API calls and ensure proper access control.
            </p>

            Image for Authorization Step
            <div className="step-image-container">
              <img src={tokenImg} alt="Step 3: Authorize with Token Screenshot" className="step-image" />
            </div>


          </section> */}

          {/* Step 4: Use the API */}
          <section className="api-section" id="useAPI" ref={(el) => sectionsRef.current.push(el)}>
            <h3 className="api-subtitle">
              <Filter3Icon /> Use the API
            </h3>
            <p className="api-description">
              Now you're ready to use the API! Make requests to the endpoints, retrieve data, and integrate the API's functionality into your application.
            </p>
            <p className="api-description">
              Follow the API documentation for detailed instructions on endpoints, parameters, and response formats.
            </p>

            {/* Image for Use API Step */}
            <div className="step-image-container">
              <img src={useApiImg} alt="Step 4: Use the API Screenshot" className="step-image" />
            </div>


          </section>



          {/* Conclusion */}
          <section className="api-section" id="conclusion" ref={(el) => sectionsRef.current.push(el)}>
            <h3 className="api-subtitle">Conclusion<Psychology /></h3>
            <p className="api-description">
              startelelogic APIs offers developers simplified access to critical telco network functionalities, making it easier to enhance their applications. With features like device location tracking and OTP validation.</p>
            <p className="api-description">This API effectively reduces integration complexities and encourages innovation within the telecommunications sector. By leveraging these powerful tools, developers can craft impactful solutions that improve user experiences and support growth in the digital landscape.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ApiDocumentation;
