import { CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
  return (
    <>
    <div style={{display:'flex',justifyContent:"center",
        alignItems:"center",height:"100vh"
    }}>
    <CircularProgress color="inherit"  size={70} />
    </div>
    </>
  )
}

export default Loading