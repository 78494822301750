import React from 'react'
import './DeviceSwaggerApiReference.css'
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
// import cardImg from '../../Assets/codeImg.png'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { lookup } from 'country-data';  // import the country-data package





const DeviceSwaggerApiReference = () => {

  const LoginStatus = localStorage.getItem('isLoggedIn')
  const [country, setCountry] = useState('');
  const UserUUId = localStorage.getItem('UserUUId');
  const AuthToken = localStorage.getItem('AuthToken');
  const APIKey = localStorage.getItem('APIKey');


  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const REACT_APP_BASE_URL_NETWORK = process.env.REACT_APP_BASE_URL_NETWORK;





  // Swagger ui openApiSpec function for showing the api name, url and path name 

  const openApiSpec = {
    "openapi": "3.0.0",
    "info": {
      "title": "Device Location",
      "version": "1.0.0"
    },
    "components": {
      // "securitySchemes": {
      //   "bearerAuth": {
      //     "type": "http",
      //     "scheme": "bearer",
      //     "bearerFormat": "JWT"
      //   }
      // },
      "parameters": {
        "ContentType": {
          "name": "Content-Type",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": "application/json"
          },
          "description": "Content type for the request"
        },
        "APIKey": {
          "name": "X-API-Key",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": APIKey // Dynamically set the default from localStorage
          },
          "description": "API Key for authentication"
        }
      }

    },
    // "security": [
    //   {
    //     "bearerAuth": []
    //   }
    // ],
    "servers": [
      {
        "url": `${REACT_APP_BASE_URL_NETWORK}`
      }
    ],
    "paths": {
      "/get-device-location": {
        "post": {
          "tags": ["Device Location"],
          "summary": "Device Location",
          "requestBody": {
            "description": "Device Location credentials",
            "required": true,
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {  // This should be lowercase
                    "device": {
                      "type": "object", // The device should be defined as an object
                      "properties": {
                        "phoneNumber": {
                          "type": "string",
                          "default": "+33699901032"
                        }
                      }
                    },
                    "maxAge": {
                      "type": "integer",
                      "default": 60
                    }
                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Ok"
            },
            "400": {
              "description": "Bad Request"
            },
            "401": {
              "description": "Unauthorized"
            },
            "403": {
              "description": "Forbidden"
            },
            "404": {
              "description": "Not Found"
            },
            "405": {
              "description": "Method Not Allowed"
            },
            "500": {
              "description": "Internal Server Error"
            },
            "502": {
              "description": "Bad Gateway"
            }
          }
        }
      }
    }
  };




  const requestInterceptor = (req) => {
    if (APIKey) {
      req.headers['X-API-Key'] = APIKey;
    }
    return req;
  }



  // ResponseInterceptor for sending the api response in the logs api parameter

  // const responseInterceptor = async (res) => {
  //   // const data = res.body;
  //   // console.log(data, 'dataaaaaaaaaaaaaaa');

  //   if (res.status === 401) {
  //     // Display a custom message to the user
  //     alert("Unauthorized access. Please access your token from the profile page.");
  //     window.location.href = '/user-setting';

  //     return;
  //   }

  //   const FirstName = localStorage.getItem('FirstName');
  //   const LastName = localStorage.getItem('LastName');

  //   const FullName = FirstName.trim() + " " + LastName.trim()

  //   // console.log(FullName, 'ffffffffffff')

  //   const date = new Date();

  //   // formating the date 
  //   const UTCFormatDate = date.toISOString();



  //   const requestOptions = {
  //     method: "GET",
  //     redirect: "follow"
  //   };

  //   // For getting the ip address and network location for logs api parameter

  //   const IPRes = await fetch("https://ipinfo.io/122.161.51.67?token=781b8c09d82125", requestOptions)

  //   const IPResponse = await IPRes.json();
  //   const countryCode = IPResponse.country;

  //   // convert the country iso2 to full country name 
  //   const getCountryName = (iso2Code) => {
  //     const countryData = lookup.countries({ alpha2: iso2Code })[0];
  //     return countryData ? countryData.name : 'Unknown Country';
  //   }

  //   const countryName = getCountryName(countryCode) || 'Unknown Country';
  //   // setCountry(countryName);






  //   //  logs api url 
  //   await fetch(`${REACT_APP_BASE_URL_NETWORK}/logs`, {
  //     method: 'POST', headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${AuthToken}`
  //     }, body: JSON.stringify({
  //       "status": "1",
  //       "method": "POST",
  //       "endpoint": "/get-device-location",
  //       "uuid": UserUUId,
  //       "name": FullName,
  //       "ipAddress": IPResponse.ip,
  //       "city": IPResponse.city,
  //       "region": IPResponse.region,
  //       "country": countryName,
  //       "location": IPResponse.loc,
  //       "org": IPResponse.org,
  //       "postal": IPResponse.postal,
  //       "timezone": IPResponse.timezone,
  //       "mac": "",
  //       "date": UTCFormatDate,
  //     }
  //     )
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       // console.log(data); 
  //     })

  //   return res;
  // }

  // page open for the top when its to be open 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Phone Number to be used for call the api

  const phoneNumbers = ["33699901032", ];


  return (
    <div className="swagger-mainContainer">


      <h2>Device Location</h2>
      <p>
        Developers and service providers can access real-time mobile device location data using a programmable interface offered by the Device Location API. With the provision of a distinct identification, like an MSISDN, users can obtain accurate latitude and longitude coordinates as well as an accuracy radius.
      </p>

      <div className="introduction-content">
        <h2>Introduction</h2>
        <p>
          Using network data, the Device Location API provides mobile devices with location information that is either real-time or almost real-time. It makes it possible for service providers to safely obtain location information linked to particular mobile numbers.
        </p>
        <p>
          Numerous applications, such as fraud detection, location-based marketing, and enhancing user experiences, can benefit from this API. Providers can improve user engagement and stop fraudulent actions by using this API to track the geographic movement of devices.
        </p>
        <p>This API answers the following questions:</p>
        <ul>
          <li>Where is the device currently, or where was it last seen?</li>
          <li>Has the gadget moved in a particular amount of time?</li>
        </ul>
      </div>

      <div className="relevant-terms-content">
        <h2>Relevant terms and definitions</h2>
        <ul>
          <li>
            <strong>Device Location:</strong> A mobile device's geographic position as ascertained by the network provider through the use of signals from technologies such as GPS or cell tower triangulation is referred to as device location.
          </li>
          <li>
            <strong>Geolocation:</strong> This is the process of pinpointing a device's exact location using triangulation methods based on networks or GPS data.
          </li>
        </ul>
      </div>

      {LoginStatus !== "true" &&
        <div className="swagger-info">
          <h2  >How to Use Our APIs</h2>
          <p>
            Our APIs provide a range of services that you can explore through the documentation above.
            You can use this interface to test API endpoints once you log in. For now, you can review the
            available endpoints and see how to structure your requests.
          </p>
          <ul>
            <li><strong>Authentication:</strong> Login to get your API Access.</li>
            <li><strong>Access Token:</strong> After logging in, click on the username box on the right side and click on the user setting to navigate the user settings page. From there, copy your access token to use for API calls</li>
            <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,Device Location Verification and OTP validation.</li>
            <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
          </ul>
          <p>
          </p>
        </div>
      }


      <div className="api-functionality-content">
        <h2>API functionality</h2>
        <p>The following operation is provided by the API::</p>
        <ul>
          <li>POST retrieve-location: Returns a device's latest known location or real-time location.</li>
          {/* <li>POST check-movement: Checks if the device has moved from one location to another during a past period (defined by the 'timeFrame' attribute) for a given phone number.</li> */}
        </ul>
      </div>


      <div className='phone-number' >
        <h2>Specific Number to API implementation</h2>
        <p>Use this phoneNumber (other will not work):</p>
        <p style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start', backgroundColor: 'lightgray', color: 'black', height: '50px', margin: '0px 0px ', padding: '0px 10px ', borderBottom: '1px solid gray'
        }}>Phone Number</p>


        {phoneNumbers.map((phonenumber, index) => {

          return (
            <>
              <hr />
              <p key={index} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', padding: '0px 10px', margin: '10px 0px '
              }}>{phonenumber}  </p>
            </>
          )
        })}

      </div>

      <div className="further-info-content">

        <h2>Further Info</h2>
        <p>
          The Device Location API retrieves location data securely and in accordance with industry standards. It guarantees the privacy of user location data by adhering to multiple privacy rules. Please refer to the documentation for further information regarding device location standards.

        </p>
        <p>(FAQs will be added in a later version of the documentation)</p>

      </div>

      {
        LoginStatus === "true" ? (
          <Card className="hero-card">

            {/* Show the swagger ui for api calling when the user is loggedIn */}

            <SwaggerUI spec={openApiSpec} requestInterceptor={requestInterceptor} />


          </Card>) : (
          <>
            <div className="login-message">
              <a href='/login' >Please Login to Access Full API Features</a>
              <p>
                You need to log in to fully interact with the APIs, but you can still explore the API documentation below.
              </p>
            </div>

            <Card className="hero-card">

              {/* Render Swagger UI for non-logged-in users as read-only */}

              <SwaggerUI
                spec={openApiSpec}

                // responseInterceptor={responseInterceptor}
                docExpansion="none" // Collapse the docs for easier reading
                supportedSubmitMethods={LoginStatus === "true" ? ['get', 'post', 'put', 'delete'] : []} // Disable try it out if not logged in
              />
            </Card>

            {/* <div className="swagger-info">
              <h3 style={{ marginTop: '20px' }} >How to Use Our APIs</h3>
              <p>
                Our APIs provide a range of services that you can explore through the documentation above.
                You can use this interface to test API endpoints once you log in. For now, you can review the
                available endpoints and see how to structure your requests.
              </p>
              <ul>
                <li><strong>Authentication:</strong> Login to get your API Access.</li>
                <li><strong>Access Token:</strong>After logging in, click on the username box on the right side to navigate to the user settings page. From there, copy your access token to use for API calls</li>
                <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,device status monitoring and OTP validation.</li>
                <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
              </ul>
              <p>
              </p>
            </div> */}
          </>
        )
      }



    </div>

  )
}

export default DeviceSwaggerApiReference
