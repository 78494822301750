import React, { useEffect } from 'react'
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";
import paymentSuccessGif from '../../../Assets/Star.png'
import SuccessIcon from '../../../Assets/check.png'
import { Bars } from 'react-loader-spinner';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState } from 'react';

//These imports are for the reports download
import jsPDF from "jspdf";
import "jspdf-autotable";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // bgcolor: "background.paper",
    bgcolor: "#FFFFFF"
};


const PaymentSuccess = () => {

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const APIKey = localStorage.getItem('APIKey');

    const PaymentSessionID = localStorage.getItem('SessionId');

    // payment details modal states start here 

    const [TransactionId, setTransactionId] = useState('');
    const [PaymentType, setPaymentType] = useState('');
    const [PaymentEmailId, setPaymentEmailId] = useState('');
    const [PlanName, setPlanName] = useState('');
    const [Amount, setAmount] = useState('');
    const [PaymentStatus, setPaymentStatus] = useState('')
    const [AllDataResponse, setAllDataResponse] = useState('');

    const [openBox, setOpenBox] = useState(true);
    const navigate = useNavigate();

    const generatePDF = () => {
        const report = new jsPDF("portrait", "mm", "a4");

        const data = [];
        const headers = ['Field', 'Value'];

        const tableData = document.querySelectorAll('.payment_detail p');
        tableData.forEach(row => {
            const field = row.childNodes[0].textContent.trim();
            const value = row.childNodes[1].textContent.trim();
            data.push([field, value]);
        });

        report.autoTable({
            head: [headers],
            body: data,
            theme: "striped",
            styles: { fontSize: 8 },
            margin: { top: 15 },
        });
        report.save("Payment_Success_Details.pdf");
    };

    // payment filldetails api function start here 

    const PaymentFillDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("X-API-Key", `${APIKey}`);

        const raw = "";

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/payment/plan/payment-details?session_id=${PaymentSessionID}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
                if (result.status == 1 || 2) {
                    // console.log(result.session_url.planName);
                    // console.log(result.session_url.plan_category);
                    // console.log(result.session_url.api_name);

                    setTransactionId(result.payment_details.id);
                    setPaymentType(result.payment_details.payment_methods.type)
                    setPaymentEmailId(result.payment_details.billing_details.email)
                    setPlanName(result.session_url.plan_name)
                    setAmount(result.payment_details.amount)
                    setPaymentStatus(result.payment_details.status)
                    setAllDataResponse(result.status)

                    // handleBuyPlanApi(result.session_url.plan_name, result.session_url.plan_category, result.session_url.api_name)
                }
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        PaymentFillDetails();
    }, []);

    const handleHomenavigate = () => {
        navigate('/')
    }

    const handleBuyPlanApi = (PlanName, planCategory, ApiName) => {

        const myHeaders = new Headers();
        myHeaders.append("X-API-Key", `${APIKey}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "api_name": ApiName,
            "plan_name": PlanName,
            "plan_category": planCategory
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/payment/plan/buy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
            })
            .catch((error) => console.error(error));

    }

    return (
        <>
            <Modal open={openBox}>
                <Box sx={style} className="SuccessPaymentModalStyling">
                    <img src={paymentSuccessGif} className="success_gif" alt="Payment Success" />
                    {/* {billingListShowLoader ? ( */}
                    <div className="paymnet_main">
                        <div className="payment_detail" id="Table-Data">
                            <h2>Payment Successful    <img src={SuccessIcon} className='SuccessIcon' alt="Sucess icon" /></h2>

                            <hr />
                            {AllDataResponse === 1 &&

                                <>
                                    <p>
                                        Transaction Id :
                                        <span>{TransactionId} </span>

                                    </p>
                                    <p>
                                        Payment Type :
                                        <span style={{ textTransform: 'capitalize' }}> {PaymentType} </span>
                                    </p>
                                    <p>
                                        Email ID :
                                        <span> {PaymentEmailId} </span>
                                    </p>
                                </>

                            }
                            <p>
                                Plan Name :
                                <span> {PlanName} </span>
                            </p>
                            <p>
                                Amount :
                                <span>$ {(Amount) / 100} </span>
                            </p>
                            <p>
                                Payment Status:
                                <span style={{ textTransform: 'capitalize' }}>{PaymentStatus} </span>
                            </p>
                        </div>

                        <div style={{
                            display: "flex",
                            padding: "15px 0px",
                            width: '50%',
                            justifyContent: 'space-between'
                        }}>
                            <Button
                                style={{ color: "Green", transform: "scale(.9)" }}
                                variant="outlined"
                                color="inherit"
                                onClick={generatePDF}
                            >
                                Print
                            </Button>

                            <Button
                                style={{ color: "red", transform: "scale(.9)" }}
                                variant="outlined"
                                color="inherit"
                                onClick={handleHomenavigate}
                            >
                                Close
                            </Button>
                        </div>

                    </div>
                    {/* ) : ( */}
                    {/* <div style={{
                            width: '100%',
                            height: '20vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Bars
                                height="30"
                                width="30"
                                color="#37BAE4"
                                visible={true}
                            />
                        </div> */}
                    {/* )} */}
                </Box>
            </Modal>
        </>
    )
}

export default PaymentSuccess;