import React from 'react';
import './Footer.css';
import StarteleName from '../../Assets/stllogo.png'
import { Link } from 'react-router-dom';
import { DataObject, DevicesOther, Facebook, Instagram, LinkedIn, MyLocation, Phone, SimCard, SystemSecurityUpdate, SystemSecurityUpdateGood, Twitter, YouTube } from '@mui/icons-material';

const Footer = () => {

  return (
    <>
      {/* <footer className="footer">
        <div className="footer-logo">
          <img
            src={StarteleName}
          />

        </div>

        <div className="footer-links">
          <div className="footer-column">
            <h4>PRODUCTS</h4>
            <ul>
              <li><Link to="/device-location">Device Location</Link></li>
              <li><Link to="/sim-swap-detection">SIM Swap Detection</Link></li>
              <li><Link to="/device-status-monitoring">Device Status Monitoring</Link></li>
              <li><Link to="/otp-validation">OTP Validation</Link></li>

            </ul>
          </div>

          <div className="footer-column">
            <h4>CORPORATE</h4>
            <ul>
              <li><Link to='/contact-us'>Contact Us</Link></li>
              <li><a href="#">Newsroom</a></li>
              <li><a href="#">Events</a></li>


              <li><a href="#">Careers</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>SOCIAL</h4>
            <ul>
              <li><a href="https://www.facebook.com/startelelogic/" target='_blank'>Facebook</a></li>
              <li><a href="https://www.linkedin.com/company/startelelogic/" target='_blank'  >LinkedIn</a></li>
              <li><a href="https://x.com/startelelogic?lang=en" target='_blank'>Twitter</a></li>
              <li><a href="https://www.youtube.com/channel/UCaVufyKNouVaczs2gyoYTWA" target='_blank'>YouTube</a></li>
              <li><a href="https://www.instagram.com/startelelogic/" target='_blank'>Instagram</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>MARKETPLACE</h4>
            <ul>
              <ul>
                <li><Link to='/developer-center' >Developer center</Link></li>
              </ul>
            </ul>
          </div>
        </div>
      </footer> */}

      <div className="Footer">
        <div className="footer_top">
          <div className="sections" id="section_1">
            <img src={StarteleName} id="FooterLogo" />
            <p>With cutting-edge, network edge, and API-driven networks, the future of intelligent connectivity is being redefined, enabling top service providers and multinational corporations. </p>
          </div>

          <div className="sections" id="section_2">
            <h3>Products</h3>
            <Link to="/device-location"> <MyLocation /> Device Location</Link>
            <Link to="/sim-swap-detection"> <SimCard /> SIM Swap Detection</Link>
            <Link to="/device-location-monitoring"> <DevicesOther /> Device Location verificatioin</Link>
            <Link to="/otp-validation"> <SystemSecurityUpdateGood /> OTP Validation</Link>
          </div>

          <div className="sections" id="section_3">
            <h3>Corporate</h3>
            <Link to="/contact-us"> <Phone /> Contact Us</Link>
          </div>

          <div className="sections" id="section_4">
            <h3>MarketPlace</h3>
            <Link to="/developer-center"> <DataObject /> Developer Center</Link>
          </div>
        </div>

        <hr id="divider" />

        <div className="footer_end">
          <p>© 2024 startelelogic · All Rights Reserved </p>
          {/* <h3> | </h3> */}
          <div className="icons_box">
            <a href="https://www.facebook.com/startelelogic/" target='_blank' rel="noopener noreferrer" ><Facebook id="icon" /></a>
            <a href="https://www.linkedin.com/company/startelelogic/" target='_blank' rel="noopener noreferrer" > <LinkedIn id="icon" /> </a>
            <a href="https://x.com/startelelogic?lang=en" target='_blank' rel="noopener noreferrer"> <Twitter id="icon" /> </a>
            <a href="https://www.youtube.com/channel/UCaVufyKNouVaczs2gyoYTWA" target='_blank' rel="noopener noreferrer"><YouTube id="icon" /> </a>
            <a href="https://www.instagram.com/startelelogic/" target='_blank' rel="noopener noreferrer"> <Instagram id="icon" /> </a>
          </div>
        </div>
      </div>

    </>
  );
};

export default Footer;
