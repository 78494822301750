import './App.css';
import {Suspense,lazy} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Sessions
import Home from './Components/HomePage/HomePage';
import Login from './Components/Sessions/Login/Login';
import SignUp from './Components/Sessions/SignUp/SignUp';
import ForgotPassword from './Components/Sessions/Forgot Password/ForgotPassword'

// Navbar
import Navbar from './Components/Navbar/Navbar.jsx'
import UserProfile from './Components/UserProfile/UserProfile';
import ContactPage from './Components/ContactPage/ContactPage.jsx';

// Products
import OTPValidation from './Components/Products/OTPValidation/OTPValidation.jsx';
import DeviceLocation from './Components/Products/DeviceLocation/DeviceLocation.jsx';
import SIMSwapDetection from './Components/Products/SIMSwapDetection/SIMSwapDetection.jsx';
import DeviceStatusMonitoring from './Components/Products/DeviceStatusMonitoring/DeviceStatusMonitoring.jsx';

import DeveloperCenter from './Components/DeveloperCenter/DeveloperCenter.jsx';
import Guides from './Components/Guides/Guides.jsx';
import Loading from './Components/Loading/Loading.jsx';
import Plans from './Components/Plans/Plans.jsx';
// import AdminDashboard from './Components/Admin/AdminDashboard/AdminDashboard.jsx';
import PaymentSuccess from './Components/Plans/Payment/PaymentSuccess.jsx';
import PaymentCancel from './Components/Plans/Payment/PaymentCancel.jsx';
import { AuthProvider } from './Components/customHook/AuthContext.jsx';
import ProtectedRoutes from './Components/protectedRoutes/ProtectedRoutes.jsx';





const Dashboard = lazy(() => import('./Components/Dashboard/Dashboard.jsx'));


function App() {


  return (
    <div className='App' >
      <AuthProvider>
      <Router>
        <Navbar />
        <ToastContainer />
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          

          <Route path="/guides" element={<Guides />} />

          {/* products  route*/}
          <Route path="/device-location" element={<DeviceLocation />} />
          <Route path="/sim-swap-detection" element={<SIMSwapDetection />} />
          <Route path="/device-location-monitoring" element={<DeviceStatusMonitoring />} />
          <Route path="/otp-validation" element={<OTPValidation />} />

         

          {/* <Route path="/user-setting" element={<UserProfile />} /> */}

          {/* <Route path="/dashboard" element={<Suspense fallback={<Loading/>}>
            <Dashboard/>
          </Suspense>} /> */}
          

         

        <Route path='/plans' element={<Plans/>}/>

          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/developer-center" element={<DeveloperCenter />} />
          <Route path="*" element={<Navigate to="/login" />} />

          {/* payment page path */}
          
          {/* <Route path='/payment_success' element={<PaymentSuccess/>} />
          <Route path='/payment_failed' element={<PaymentCancel/>} /> */}
       
          <Route path='/payment_success' element={<ProtectedRoutes><PaymentSuccess/></ProtectedRoutes>} />
          <Route path='/payment_failed' element={<ProtectedRoutes><PaymentCancel/></ProtectedRoutes>} />
          

          <Route path="/user-setting" element={<ProtectedRoutes><UserProfile />
            </ProtectedRoutes>} />

      {/* <Route path="/dashboard" element={<ProtectedRoutes>
        <Suspense fallback={<Loading/>}>
        <Dashboard/>
      </Suspense> 
        </ProtectedRoutes>}/> */}
        <Route path='/dashboard' element={<Suspense fallback={<Loading/>}>
        <Dashboard/>
      </Suspense> }/>

        </Routes>
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
