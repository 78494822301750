import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import './DeviceStatusMonitoringApiReference.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
// import cardImg from '../../Assets/codeImg.png'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import { lookup } from 'country-data';  // import the country-data package

const DeviceStatusMonitoringApiReference = () => {
  // API Reference Tab panel states 
  const [ApiReferenceValue, setApiReferenceValue] = useState('1');

  const handleAPITab = (event, newValue) => {
    setApiReferenceValue(newValue);
  };

  const REACT_APP_BASE_URL_NETWORK = process.env.REACT_APP_BASE_URL_NETWORK
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;



  const LoginStatus = localStorage.getItem('isLoggedIn')
  const [country, setCountry] = useState('');
  const UserUUId = localStorage.getItem('UserUUId')
  const AuthToken = localStorage.getItem('AuthToken'); // for getting the auth token from local storage
  const APIKey = localStorage.getItem('APIKey'); // getting the api key for call api 



  // OpenApiSpec for showing the api name, url, response and parameter in the swagger
  const openApiSpec = {
    "openapi": "3.0.0",
    "info": {
      "title": "Device Location Verification",
      "version": "1.0.0"
    },
    "components": {
      // "securitySchemes": {
      //   "bearerAuth": {
      //     "type": "http",
      //     "scheme": "bearer",
      //     "bearerFormat": "JWT"
      //   }
      // },

      "parameters": {
        "ContentType": {
          "name": "Content-Type",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": "application/json"
          },
          "description": "Content type for the request"
        },
        "APIKey": {
          "name": "X-API-Key",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": APIKey // Dynamically set the default from localStorage
          },
          "description": "API Key for authentication"
        }
      }
    },
    // "security": [
    //   {
    //     "bearerAuth": []
    //   }
    // ],
    "servers": [
      {
        "url": `${REACT_APP_BASE_URL_NETWORK}`
      }
    ],
    "paths": {
      "/device-status-monitoring": {
        "post": {
          "tags": ["Device Location Verification"],
          "summary": "Device Location Verification",
          "requestBody": {
            "description": "Device status credentials",
            "required": true,
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "phone_number": {
                      "type": "string",
                      "default": "+33699901032"

                    },
                    "max_age": {
                      "type": "integer",
                      "default": 60
                    },

                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Ok"
            },
            "400": {
              "description": "Bad Request"
            },
            "401": {
              "description": "Unauthorized"
            },
            "403": {
              "description": "Forbidden"
            },
            "404": {
              "description": "Not Found"
            },
            "405": {
              "description": "Method Not Allowed"
            },
            "500": {
              "description": "Internal Server Error"
            },
            "502": {
              "description": "Bad Gateway"
            },

          }
        }
      }
    }
  };

  const requestInterceptor = (req) => {
    if (APIKey) {
      req.headers['X-API-Key'] = APIKey;
    }
    return req;
  }


  // Storing the api response and sending into the logs api

  // const responseInterceptor = async (res) => {
  //   // const data = res.body;
  //   // console.log(data, 'dataaaaaaaaaaaaaaa');

  //   if (res.status === 401) {
  //     // Display a custom message to the user
  //     alert("Unauthorized access. Please access your token from the profile page.");
  //     window.location.href = '/user-setting';
  //     return;
  //   }

  //   const FirstName = localStorage.getItem('FirstName');
  //   const LastName = localStorage.getItem('LastName');

  //   const FullName = FirstName.trim() + " " + LastName.trim()

  //   // console.log(FullName, 'ffffffffffff')

  //   const date = new Date();

  //   // formatin the date 
  //   const UTCFormatDate = date.toISOString();



  //   const requestOptions = {
  //     method: "GET",
  //     redirect: "follow"
  //   };

  //   // for getting the ip address and network location for logs api parameter

  //   const IPRes = await fetch("https://ipinfo.io/122.161.51.67?token=781b8c09d82125", requestOptions)

  //   const IPResponse = await IPRes.json();
  //   const countryCode = IPResponse.country;

  //   // for covnert isocode2 to full country name 
  //   const getCountryName = (iso2Code) => {
  //     const countryData = lookup.countries({ alpha2: iso2Code })[0];
  //     return countryData ? countryData.name : 'Unknown Country';
  //   }

  //   const countryName = getCountryName(countryCode) || 'Unknown Country';
  //   // setCountry(countryName);







  //   //  logs api url here 
  //   await fetch(`${REACT_APP_BASE_URL_NETWORK}/logs`, {
  //     method: 'POST', headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${AuthToken}`
  //     }, body: JSON.stringify({
  //       "status": "1",
  //       "method": "POST",
  //       "endpoint": "/get-device-location",
  //       "uuid": UserUUId,
  //       "name": FullName,
  //       "ipAddress": IPResponse.ip,
  //       "city": IPResponse.city,
  //       "region": IPResponse.region,
  //       "country": countryName,
  //       "location": IPResponse.loc,
  //       "org": IPResponse.org,
  //       "postal": IPResponse.postal,
  //       "timezone": IPResponse.timezone,
  //       "mac": "",
  //       "date": UTCFormatDate,
  //     }
  //     )
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       // console.log(data); 
  //     })

  //   return res;
  // }

  return (
    <>
      <div className="main-container-device-status">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={ApiReferenceValue}>
            {/* <Box sx={{ borderColor: 'divider' }}>
              <TabList onChange={handleAPITab} aria-label="lab API tabs example">
                <Tab label="SwaggerUI" value="1" />
                <Tab label="Rapidoc" value="2" />
              </TabList>
            </Box> */}
            <TabPanel value="1">
              <div className="swagger-mainContainer">
                <h2> Device Location Verification</h2>
                <p>Developers may monitor device location in real-time and obtain vital information to improve operational efficiency using the Device  Location Verification API.</p>

                <div className="introduction-content">
                  <h2>Introduction</h2>
                  <p>Real-time updates on the operational condition of devices inside a network are provided by this API, facilitating prompt issue discovery and guaranteeing optimal performance.</p>
                  <p>In order to facilitate more informed decision-making, the API enables users to track device connection, performance metrics, and get notifications for abnormalities.</p>
                  <p>The API responds to important queries like:</p>
                  <p>
                    <li>The gadget is it offline or online?</li>{LoginStatus !== "true" &&
                      <div className="swagger-info">
                        <h2  >How to Use Our APIs</h2>
                        <p>
                          Our APIs provide a range of services that you can explore through the documentation above.
                          You can use this interface to test API endpoints once you log in. For now, you can review the
                          available endpoints and see how to structure your requests.
                        </p>
                        <ul>
                          <li><strong>Authentication:</strong> Login to get your API Access.</li>
                          <li><strong>Access Token:</strong> After logging in, click on the username box on the right side and click on the user setting to navigate the user settings page. From there, copy your access token to use for API calls</li>
                          <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,Device Location Verification and OTP validation.</li>
                          <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
                        </ul>
                        <p>
                        </p>
                      </div>
                    }
                    <li>What is the device's current state of performance?</li>
                  </p>
                </div>

                <div className="relevant-terms-content">
                  <h2>Relevant terms and definitions</h2>
                  <p>
                    <li>
                      Device Status: Provides information on a device's operational state, such as whether it is online, offline, or experiencing a problem.
                    </li>
                  </p>
                </div>

                {LoginStatus !== "true" &&
                  <div className="swagger-info">
                    <h2  >How to Use Our APIs</h2>
                    <p>
                      Our APIs provide a range of services that you can explore through the documentation above.
                      You can use this interface to test API endpoints once you log in. For now, you can review the
                      available endpoints and see how to structure your requests.
                    </p>
                    <ul>
                      <li><strong>Authentication:</strong> Login to get your API Access.</li>
                      <li><strong>Access Token:</strong> After logging in, click on the username box on the right side to navigate to the user settings page. From there, copy your access token to use for API calls</li>
                      <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,device location verification and OTP validation.</li>
                      <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
                    </ul>
                    <p>
                    </p>
                  </div>
                }

                <div className="api-functionality-content">
                  <h2>API functionality</h2>
                  <p>The following operation is supported by the API:</p>
                  <p>
                    <li>POST status: Returns a device's current status in real time, including information on how it is operating.</li>
                  </p>
                </div>

                <div className="further-info-content">
                  <div className="further-info-title">
                    <h2>Further Information</h2>
                    <p>Please consult the API documentation for best practices and comprehensive usage instructions on how to monitor device states efficiently.</p>
                    <p>(FAQs will be added in a later version of the documentation)</p>
                  </div>
                </div>
              </div>


              {
                LoginStatus === "true" ? (<Card className="hero-card">

                  {/* Showing the swagger ui for loggedIn user  */}

                  <SwaggerUI spec={openApiSpec} requestInterceptor={requestInterceptor} />


                </Card>) : (
                  <>
                    <div className="login-message">
                      <a href='/login'>Please Login to Access Full API Features</a>
                      <p>
                        You need to log in to fully interact with the APIs, but you can still explore the API documentation below.
                      </p>
                    </div>

                    <Card className="hero-card">
                      {/* Render Swagger UI for non-logged-in users as read-only */}
                      <SwaggerUI
                        spec={openApiSpec}

                        // responseInterceptor={responseInterceptor}
                        docExpansion="none" // Collapse the docs for easier reading
                        supportedSubmitMethods={LoginStatus === "true" ? ['get', 'post', 'put', 'delete'] : []} // Disable try it out if not logged in
                      />
                    </Card>

                    {/* <div className="swagger-info">
                      <h3 style={{ marginTop: '20px' }} >How to Use Our APIs</h3>
                      <p>
                        Our APIs provide a range of services that you can explore through the documentation above.
                        You can use this interface to test API endpoints once you log in. For now, you can review the
                        available endpoints and see how to structure your requests.
                      </p>
                      <ul>
                        <li><strong>Authentication:</strong> Login to get your API Access.</li>
                        <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection, and OTP validation.</li>
                        <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
                      </ul>
                      <p>
                        For more information, please visit our <a href="/api-docs">API documentation page</a> or contact support.
                      </p>
                    </div> */}
                  </>
                )
              }

            </TabPanel>
            {/* <TabPanel value="2">Item Two</TabPanel> */}
          </TabContext>
        </Box>
      </div>
    </>
  );
};

export default DeviceStatusMonitoringApiReference;
