import React, { useEffect } from 'react';


import './SIMSwapDetection.css'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react'

import ApiReference from './ApiReference/ApiReference';
import Footer from '../../Footer/Footer';

const SIMSwapDetection = () => {
  const [Tabvalue, setTabValue] = useState('1');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // For open the page to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>

      <Box className='SimSwapBox' sx={{ typography: 'body1' }}>
        <div className='SimSwapHeading'>

          <h1>SIM Swap </h1>
          {/*  */}
          {/* <div className='SimSwapicon'>
            <div style={{ width: '70%' }}>
              <p>Integrate real-time SIM card activation checks to enhance security and prevent fraud</p>
              <br />

              <Button variant="contained" size="large">
                Use this API
              </Button>
              <Button variant='outlined' size="large" style={{ margin: '10px ' }}> Contact us</Button>

            </div>
            <img src={SIMSwap} />
          </div> */}


        </div>
        <div className='SimSwapTabDetails'>
          <TabContext value={Tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Overview" value="1" />
                {/* <Tab label="Getting Started" value="2" /> */}
                <Tab label="API Reference" value="3" />
                {/* <Tab label="Terms" value="4" />
                <Tab label="Contact us" value="5" /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className='OverviewDetails'>

                <p className='main-heading'> SIM Swap <span style={{ fontSize: '18px', fontWeight: '500', }}>API is part of</span> <span id='Apidocs' style={{ textDecoration: 'underline', }}>startelelogic APIs.</span>
                </p>

                <p >
                  The mobile devices (MSISDNs) in this short list are restricted to our lab's use solely in this sandboxed version.
                </p>

                <h3>What it does?</h3>

                <p>
                  Real-time confirmation of a SIM card's activation date within the mobile network—which shows whether a mobile line has been switched to a new SIM card—is made possible via the  SIM Swap API.
                </p>

                <p>This API is crucial for preventing fraud since it improves SIM-based authentication techniques like SMS one-time passwords, which lowers the possibility of account takeover. Fraudsters can change passwords or get verification codes to access secure accounts by using SIM swapping techniques to intercept SMS communications.</p>

                <p>Applications can use the SIM switch API to find out when a certain mobile line's last SIM switch was completed. Service providers (SPs) can obtain this data securely and with ease thanks to the API's seamless integration.It efficiently handles  main resources, providing answers to the following queries::</p>

                <ul>
                  <li>
                    When was the last time a SIM was switched?</li>
                  {/* <li>Has a SIM Swap occurred during last n hours?</li> */}
                </ul>
              </div>

            </TabPanel>
            <TabPanel value="2">Getting Started</TabPanel>

            {/*Api Refference page importing here */}

            <TabPanel value="3"><ApiReference /></TabPanel>


            <TabPanel value="4">Terms</TabPanel>
            <TabPanel value="5">Contact us</TabPanel>
          </TabContext>

        </div>

      </Box>
      <Footer />
    </>
  );
};

export default SIMSwapDetection;