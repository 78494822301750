

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const REACT_APP_BASE_URL_NETWORK = process.env.REACT_APP_BASE_URL_NETWORK;

const APIKey = localStorage.getItem('APIKey');

// sign up api function start here 

export const SignupApi = async (FirstName, LastName, SignUpEmail, SignUpPassword, SignUpMobileNo) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "first_name": FirstName,
        "last_name": LastName,
        "email_id": SignUpEmail,
        "password": SignUpPassword,
        "mobile_number": SignUpMobileNo
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/signup`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}

// verify email function start here 

export const VerifyEmail = async (SignUpEmailForOtpVerify, VerifyOtp) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        email_id: SignUpEmailForOtpVerify,
        otp: VerifyOtp,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/verify_email`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}

// resend otp api function start here 

export const ResendOtp = async (SignUpEmailForOtpVerify) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        email_id: SignUpEmailForOtpVerify,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    const respone = await fetch(`${REACT_APP_BASE_URL}/resend_otp`, requestOptions);
    const result = await respone.json();
    return result;
}


// login Api function start here 

export const LoginApi = async (email, password) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "email_id": email,
        "password": password
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/login`, requestOptions);
        // const response = await fetch("https://camara-api.callibry.com:3001/login", requestOptions)

        const result = await response.json();

      
        return result;

    }
    catch (error) {
        console.error(error);
    }
}

// Logout api function start here 

export const LogoutApi = async (email) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "email_id": email
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/logout`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error)
    }
}


// Forgot password api function start here 

export const ForgotPasswordApi = async (email) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "email_id": email
    });


    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/forget_password`, requestOptions);
        const result = await response.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}

// Verify OTP for forgot password function start here

export const VerifyOtp = async (email, newPassword, retypePassword, otp) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "email_id": email,
        "new_password": newPassword,
        "retype_new_password": retypePassword,
        "otp": otp
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const respone = await fetch(`${REACT_APP_BASE_URL}/verify_otp`, requestOptions);
        const result = respone.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}

// All Plans  api function start here

export const AllPlansData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("X-API-Key", `${APIKey}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"

    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/plans`, requestOptions);
        const result = response.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}


// Dashboard card api function start here 

export const DashboardApi = async () => {
    const myHeaders = new Headers();
    myHeaders.append("X-API-Key", `${APIKey}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/user/active/subscriptions`, requestOptions);
        const result = response.json();
        return result;

    } catch (error) {
        console.error(error);
    }
}

// Fetch All apis function start here 

export const AllBuyAPIs = async () => {
    const myHeaders = new Headers();
    myHeaders.append("X-API-Key", `${APIKey}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${REACT_APP_BASE_URL}/apis`, requestOptions);
        const result = response.json();
        return result;
        

    } catch (error) {
        console.error(error);
    }

}



