import React from 'react'
import './ContactHero.css'

const ContactHero = () => {
  return (
        <div className="contactHero-container">
        <div className="contactHero-context">
            <h1 className="contactHero-title" >
            Direct Line to Seamless   <span >Support</span> and  <span >Assistance</span>
            </h1>
            <p className="contactHero-subtitle">
            Reach out for expert guidance and hands-on support. We're here to empower you in reaching your goals confidently.
        </p>
        </div>
    </div>
  )
}

export default ContactHero