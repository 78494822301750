import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import developerImg from '../../Assets/developerCenterCard3.png'; // replace with your image path
import './DeveloperCenter.css';


// import ApiKey from '../../ApiKey/ApiKey';
import Footer from '../Footer/Footer';

const DeveloperCenter = () => {


  // open the page on the top 
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);


  const PythonCard = () => {
    
  }


  return (


    <div className="developerCenter-mainContainer">
      <div className="DeveloperCenterHero">
        <Box className="DeveloperCenterHero-content">
          <Box className="DeveloperCenterHero-text">
            <h2 className="developer-title">
              Empowering Developers with Cutting-Edge APIs & Tools
            </h2>
            <p className="developer-des" >
              Discover a suite of powerful APIs and tools designed to simplify your development process. Whether you're building mobile apps or complex integrations, our platform gives you the resources to innovate and scale with ease.
            </p>
          </Box>

          {/* {/ Right Section: Image Card /} */}
          <div className="DeveloperCenterHero-card">
            <img src={developerImg} alt="developer-center" width="400px" className="developer-image-container" />
          </div>
        </Box>
      </div>

      <div className="technical-reference">
        <h2>Technical Reference</h2>

        <div className="compatibility-api">
          <h3>Compatibility API</h3>
          <div className="api-cards">


            <div className="card">
              <h4><a href="#">REST APIs</a></h4>
              <p>
                Use our compatible REST APIs to manage resources in your startelelogic Space.
              </p>
            </div>



          </div>
        </div>

        {/* {/ Relay API Section /} */}
        <div className="relay-api">
          <h3>Relay API</h3>
          <div className="api-cards">
            {/* <div className="card">
            <h4><a href="#">JS Browser SDK</a></h4>
          </div> */}
            {/* <div className="card">
            <h4><a href="#">Node.js Server SDK</a></h4>
          </div> */}
            <p className="card" onClick={PythonCard}>
              <h4><a href="#">Python</a></h4>
            </p>
            <p className="card">
              <h4><a href="#">JS</a></h4>
            </p>
            <p className="card">
              <h4><a href="#">Php</a></h4>
            </p>
            <p className="card">
              <h4><a href="#">Ruby</a></h4>
            </p>
            <p className="card">
              <h4><a href="#">Java</a></h4>
            </p>
            <p className="card">
              <h4><a href="#">C#/.Net</a></h4>
            </p>
            <p className="card">
              <h4><a href="#">Go</a></h4>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DeveloperCenter;
