import React, { useEffect } from 'react';
import './DeviceLocation.css'
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react'
import DeviceSwaggerApiReference from './deviceApiReference/DeviceSwaggerApiReference';
import Footer from '../../Footer/Footer';

const DeviceLocation = () => {

  const [Tabvalue, setTabValue] = useState('1');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };



  // for page open to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // API Reference Tab panel states 
  const [ApiReferencevalue, setApiReferenceValue] = useState('1');

  const handleAPITab = (event, newValue) => {
    setApiReferenceValue(newValue);
  };

  return (
    <>

      <Box className='DeviceLocation' sx={{ typography: 'body1' }}>
        <div className='DeviceLocationHeading'>

          <h1>Device Location</h1>
        </div>
        <div className='DeviceLocationTabDetails'>
          <TabContext value={Tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Overview" value="1" />
                <Tab label="API Reference" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className='OverviewDetails'>

                <p className='main-heading'>Device Location <span style={{ fontSize: '18px', fontWeight: '500', }}>API is part of</span> <span id='Apidocs' style={{ textDecoration: 'underline', }}> startelelogic APIs.</span>
                </p>

                <p >
                  {/* This sandboxed version is limited to a short list of mobile devices (MSISDNs) that only work in our lab. */}
                  This sandboxed version of the API is restricted to a predefined set of mobile devices, identified by their MSISDNs (Mobile Station International Subscriber Directory Numbers).These devices are configured to work exclusively within our lab environment, ensuring that any location data or functionality is safely tested without affecting real-world devices or networks.
                </p>

                <h3>What it does?</h3>

                <p>
                  The Device Location Retrieval API allows for the retrieval of a mobile device's geographical location, represented as a circular area (in startelelogic's implementation).The accuracy of this location depends on network conditions at the subscriber's location, such as signal strength and the proximity to cell towers. In environments with dense cell coverage, the retrieved location is more precise, while in less dense areas, the location may cover a larger radius.
                </p>

                <h3>How it works?</h3>
                <p>When a requester submits a distinct mobile device identification, like an MSISDN, the Device Location API processes the request. The device's geographic location is ascertained by the API interacting with the network after this ID has been submitted.

                  Together with an accuracy radius, the response contains latitude and longitude coordinates that indicate the general location center of the device. This radius gives an indication of the precision of the location by defining the area that the device is most likely placed in. 

                </p>
              </div>

            </TabPanel>

            {/* API Reference Tab Start here  */}
            <TabPanel value="3">

              <Box sx={{ typography: 'body1' }}>
                <TabContext value={ApiReferencevalue}>

                  {/* <Box sx={{ borderColor: 'divider' }}>
                    <TabList onChange={handleAPITab} aria-label="lab API tabs example">
                      <Tab label="SwaggerUI" value="1" />
                      <Tab label="Rapidoc" value="2" />
                    </TabList>
                  </Box> */}

                  {/* SwwagerUI code start here  */}
                  
                  <TabPanel value="1">
                    <div className='ApiReferenceTab'></div>
                    <DeviceSwaggerApiReference />

                  </TabPanel>
                  {/* <TabPanel value="2">Item Two</TabPanel> */}
                </TabContext>
              </Box>
            </TabPanel>



            {/* <TabPanel value="4">
              Terms
            </TabPanel>
            <TabPanel value="5">Contact us</TabPanel> */}
          </TabContext>

        </div>

      </Box>

      <Footer />


    </>
  );
};

export default DeviceLocation;