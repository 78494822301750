


import React, { useRef } from 'react'
import './Navbar.css'
// import StarteleName from '../../Assets/StarteleLogo.png';
import StarteleName from '../../Assets/stllogo.png'
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { useState, useEffect } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { toast } from 'react-toastify';
import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faSync, faChartLine, faKey, faMobileAlt, faSimCard, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { AccessibilityOutlined, ApiOutlined, Login } from '@mui/icons-material';
import { LogoutApi } from '../APIs/Api';
import otpIcon from '../../Assets/otp_12689812.png'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Navbar = () => {
   
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeOption, setActiveOption] = useState(false);
    const navigate = useNavigate();
    const location = useLocation()

    const FirstName = localStorage.getItem('FirstName');
    const LastName = localStorage.getItem('LastName');
    const EmailId = localStorage.getItem('EmailId');
    const LoginStatus = localStorage.getItem('isLoggedIn')

    const [ShowSideNav, setShowSideNav] = useState(false);

    const customId = "forNotShowingMultipleToast"


    const [Toggle, setToggle] = useState(true)

    let activeUrl = (location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/signup')

    const ChangeToggle = () => {
        if (location.pathname === "/login") {
            setToggle(true)
        } else if (location.pathname === "/signup") {
            setToggle(false)
        }
    }

    useEffect(() => {
        ChangeToggle()
    })

    // for show the active page 
    const isActive = (path) => location.pathname === path;

    // State to manage the visibility of the user options menu
    const [menuVisible, setMenuVisible] = useState(false);

    // Function to toggle the menu visibility// FontAwesome Icon for Device Monitoring
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleProductOption = () => {
        setDropdownOpen(!dropdownOpen);
    }



    const profileInfoRef = useRef(null);


    // Close the menu if clicked outside of the ProfileInfo div
    const handleClickOutside = (event) => {
        if (profileInfoRef.current && !profileInfoRef.current.contains(event.target)) {
            setMenuVisible(false); // Close the menu
        }
        if (!event.target.closest('.dropdown-container')) {
            setDropdownOpen(false);
        }
    };

    // Set up event listener to detect clicks outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // logout api functions

    const handleLogout = async () => {
        setShowSideNav(false)

        const result = await LogoutApi(EmailId)

        if (result.status === "1") {
            toast.success(result.message, {
                toastId: customId,
                position: 'top-right',
                autoClose: 2000,
            })
          
           
            localStorage.clear();

            navigate('/login');
        }
        else if(result.status === "0"){
            if(result.message === "Account Doesn't Exists"){
                localStorage.clear();
                navigate('/login');
            }
            toast.success("Sucessfully Logged Out", {
                toastId: customId,
                position: 'top-right',
                autoClose: 2000,
            })
            

        }
        else {
            toast.error(result.message, {
                toastId: customId,
                position: 'top-right',
                autoClose: 2000,
            })
        }
        // console.log(result)
    }

    const options = [
        {
            id: 1,
            title: "Device Location",
            icon: <FontAwesomeIcon icon={faMapMarkerAlt} />, // FontAwesome Icon for Device Location
            content: {
                text: "Track devices with real-time GPS updates.",
                image: "https://via.placeholder.com/200",
            },
        },
        {
            id: 2,
            title: "Device Status Monitoring",
            icon: <FontAwesomeIcon icon={faMobileAlt} />, // FontAwesome Icon for Device Monitoring
            content: {
                text: "Monitor the status and health of your devices.",
                image: "https://via.placeholder.com/200",
            },
        },
        {
            id: 3,
            title: "SIM Swap",
            icon: <FontAwesomeIcon icon={faSimCard} />, // FontAwesome Icon for SIM Swap
            content: {
                text: "Detect SIM swap attempts and prevent fraud.",
                image: "https://via.placeholder.com/200",
            },
        },
        {
            id: 4,
            title: "OTP Validation",
            icon: <FontAwesomeIcon icon={faKey} />, // FontAwesome Icon for OTP Validation
            content: {
                text: "Validate OTP for secure transactions.",
                image: "https://via.placeholder.com/200",
            },
        },
    ];


    return (
        <>

            <div className='navbar' >
                <Link to="/" >
                    <img
                        className='navbar-brand' src={StarteleName}
                    />
                </Link>
                {
                    !activeUrl ? (<>


                        <div style={{ display: 'flex', flexDirection: 'row' }}>


                            <div className='sidenav' style={{ width: ShowSideNav ? '350px' : '0vw' }}>


                                {/* For responsive show the sidenav box-1 for showing the user name  */}

                                {LoginStatus === "true" ? (<>
                                    <div className='sidenavbox-1'>
                                        <div className='Avatar' style={{ textTransform: 'uppercase', }} >
                                            <span>
                                                {FirstName.charAt(0)}
                                            </span>

                                            <span>
                                                {LastName.charAt(0)}
                                            </span>
                                        </div>

                                        <p id='name' >
                                            {FirstName} {LastName}
                                        </p>
                                        <a href="javascript:void(0)" className="closebtn" onClick={() => setShowSideNav(!ShowSideNav)} >&times;</a>
                                    </div>
                                </>) : (
                                    <>
                                        {/* sidenav box-1 for if the user logout than show the only startele logo */}
                                        <div id='sidenavBox-Logout' className='sidenavbox-1'>
                                            <Link to="/" >
                                                <img
                                                    className='navbar-brand' src={StarteleName}
                                                />

                                            </Link>


                                            <a href="javascript:void(0)" className="closebtn" onClick={() => setShowSideNav(!ShowSideNav)} >&times;</a>
                                        </div>
                                    </>)}


                                {/* show the sidenav box-2 for menu option   */}

                                <div className='sidenavbox-2'>
                                    <ul>



                                        <li><FontAwesomeIcon icon={faLocationArrow} id='icon' /><Link to="/device-location" onClick={() => setShowSideNav(!ShowSideNav)}> Device Location </Link></li>
                                        <hr id='borderLine' />
                                        <li><FontAwesomeIcon icon={faSync} id='icon' /><Link to="/sim-swap-detection" onClick={() => setShowSideNav(!ShowSideNav)}> SIM Swap </Link></li>
                                        <hr id='borderLine' />
                                        <li><FontAwesomeIcon icon={faChartLine} id='icon' /><Link to="/device-location-monitoring" onClick={() => setShowSideNav(!ShowSideNav)}> Device Location Verification</Link></li>
                                        <hr id='borderLine' />
                                        <li><img src={otpIcon} style={{ width: '30px' }} /><Link to="/otp-validation" onClick={() => setShowSideNav(!ShowSideNav)}> OTP Validation </Link></li>
                                        <hr id='borderLine' />
                                        {/* <li><ApiOutlined id='icon' /><Link to="/apis-key" onClick={() => setShowSideNav(!ShowSideNav)}> API Keys </Link></li>
                                        <hr id='borderLine' /> */}
                                        <li><AccessibilityOutlined id='icon' /><Link to="/contact-us" onClick={() => setShowSideNav(!ShowSideNav)}> Contact Us</Link></li>
                                        <hr id='borderLine' />
                                        {
                                            LoginStatus ? (<>
                                                <li><LogoutIcon id='icon' />
                                                    <div id='Logout-In-SideNavBar' onClick={() => handleLogout()}> Logout</div>

                                                </li>
                                                <hr id='borderLine' />
                                            </>) :
                                                (<>
                                                    <li><Login id='icon' />

                                                        <Link to='/login' onClick={() => setShowSideNav(!ShowSideNav)}> Log In</Link>
                                                    </li>
                                                </>)
                                        }


                                    </ul>

                                </div>
                            </div>

                            {/* For showing the navbar menu options  */}
                            <div className='navbarMenu' >
                                <ul>
                                    <li style={isActive('/guides') ? { color: '#6675ca', borderBottom: '2px solid #ffffff', paddingBottom: '5px' } : {}}><Link to="/guides">Guides</Link></li>
                                    <li id='UserOption' onClick={handleProductOption}
                                        className="dropdown-container"
                                    ><Link>Products  </Link>
                                        {dropdownOpen && (
                                            <div className='UserOptionsMenu' >
                                                <ul>
                                                    <li  onClick={() =>  setDropdownOpen(false)}><Link to="/device-location"> Device Location</Link></li>

                                                    <li onClick={() => setDropdownOpen(false)}><Link to="/sim-swap-detection"> SIM Swap </Link></li>

                                                    <li onClick={() => setDropdownOpen(false)}><Link to="/device-location-monitoring"> Device Location Verification</Link></li>

                                                    <li onClick={() => setDropdownOpen(false)} ><Link to="/otp-validation"> OTP Validation</Link></li>
                                                </ul>
                                            </div>
                                        )}
                                    </li>

                                    <li style={isActive('/contact-us') ? { color: '#6675ca', borderBottom: '2px solid #ffffff', paddingBottom: '5px' } : {}} id='about'><Link to="/contact-us">Contact Us</Link></li>
                                    {LoginStatus ? <li style={isActive('/dashboard') ? { color: '#6675ca', borderBottom: '2px solid #ffffff', paddingBottom: '5px' } : {}} ><Link to="/dashboard">Dashboard</Link></li> : ''}
                                </ul>
                            </div>
                        </div>

                        {/* if the user login show the username on the right side  */}
                        {
                            LoginStatus === "true" ? (<>
                                <div className='ProfileInfo' onClick={toggleMenu} ref={profileInfoRef} >
                                    <>
                                        <div className='Avatar' style={{ textTransform: 'uppercase' }}>
                                            {/* <img src={Avatar} /> */}
                                            {/* {FullName && FullName.split(' ').map(name => name.charAt(0)).join('')} */}
                                            <span>{FirstName.charAt(0)}</span><span>{LastName.charAt(0)}</span>
                                        </div>

                                        <p id='name' >
                                            {FirstName} {LastName}
                                        </p>

                                    </>

                                    <div className={`UserOptionsMenu ${menuVisible ? 'visible' : ''}`}>
                                        <ul>
                                            <li

                                                onClick={() => navigate('/user-setting')}
                                            >User Settings
                                                <ManageAccountsIcon style={{
                                                    fontSize: '18px',
                                                    color: '#122E54'
                                                }} />
                                            </li>
                                            {/* } */}

                                            <li onClick={handleLogout} >Logout <LogoutIcon style={{
                                                fontSize: '18px',
                                                color: '#122E54'
                                            }} /> </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* show the login logout button if the user logut  */}

                            </>) : <div className='Toggle ProfileInfo' style={{ border: 'none' }} >
                                <Link to="/login" >
                                    <div className='loginToggle' style={{ backgroundColor: '#6D9EC0', border: '2px lightgrey solid' }}
                                    // onClick={() => setToggle(true)}
                                    >
                                        <p> Login </p>
                                    </div>
                                </Link>

                                <Link to="/signup" >
                                    <div className='signupToggle'
                                        style={{
                                            backgroundColor: '#6D9EC0',
                                            border: '2px lightgrey solid'
                                        }}

                                    >
                                        <p> Sign Up</p>
                                    </div>
                                </Link>
                            </div>
                        }
                        {/*menu option code   */}
                        <MenuIcon id='SideNavButton' onClick={() => setShowSideNav(!ShowSideNav)} />

                    </>
                    ) :


                        (<>

                            <div className='Toggle '  >
                                <Link to="/login"  >
                                    <div className='loginToggle' style={{ backgroundColor: Toggle ? '#6D9EC0' : '', }}
                                        onClick={() => setToggle(true)}
                                    >
                                        <p> Login </p>
                                    </div>
                                </Link>

                                <Link to="/signup" >
                                    <div className='signupToggle' style={{
                                        backgroundColor: Toggle ? '' : '#6D9EC0',
                                    }}
                                        onClick={() => setToggle(false)}
                                    >
                                        <p> Sign Up</p>
                                    </div>
                                </Link>
                            </div>

                        </>)
                }


                {/* Product Drawer */}

                {/* <div
                    className={`product-drawer ${activeOption ? 'open' : ''}`}
                    onMouseLeave={() => setActiveOption(false)} // Close when mouse leaves
                > */}
                {/* <div className="drawer-content"> */}
                {/* Left Side Options */}
                {/* <div className="drawer-options">
                    {options.map(option => (
                        <div
                            key={option.id}
                            className={`drawer-option ${activeOption === option.id ? 'active' : ''}`}
                            onMouseEnter={() => setActiveOption(option.id)}
                        > */}
                {/* {option.icon}
                {option.title}
                <div className="horizontal-line"></div>
            </div>
            ))}
        </div > */}


                {/* Right Side Content */}
                {/* <div className="drawer-details">
                    {activeOption && (
                        <>
                            <div className="drawer-text">
                                {options.find(option => option.id === activeOption)?.content.text}
                            </div>
                            <div className="drawer-image">
                                <img
                                    src={options.find(option => option.id === activeOption)?.content.image}
                                    alt="Product"
                                />
                            </div>
                        </>
                    )}
                </div> */}
                {/* </div> */}
                {/* </div> */}

            </div >
        </>
    )
}

export default Navbar
