import React, { useState, useEffect } from 'react'
import './HomePage.css'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Footer from '../Footer/Footer'
import { Button, Card } from '@mui/material'
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom'
import { ArrowCircleLeftRounded, ArrowCircleRightRounded } from '@mui/icons-material';

import BgVideo from '../../Assets/HomePage/HomePageVideo.mp4'
import BGIMg from '../../Assets/bg123.jpg'



import Product_Card_1_BG from '../../Assets/HomePage/Product_Card_1.png'
import Product_Card_2_BG from '../../Assets/HomePage/Product_Card_2.png'
import Product_Card_3_BG from '../../Assets/HomePage/Product_Card_3.png'
import Product_Card_4_BG from '../../Assets/HomePage/Product_Card_4.png'

import NetworkImg from '../../Assets/productIcon/networkSideImg.png'

import ValueIcon1 from '../../Assets/HomePage/Icons/1.png'
import ValueIcon2 from '../../Assets/HomePage/Icons/3.png'
import ValueIcon3 from '../../Assets/HomePage/Icons/5.png'
import ValueIcon4 from '../../Assets/HomePage/Icons/8.png'
import ValueIcon5 from '../../Assets/HomePage/Icons/10.png'
import CardSection from '../CardSection/CardSection';


const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowCircleLeftRounded
            className={className}
            style={{ ...style, fontSize: '50px', textAlign: 'center', color: '#131f32', borderRadius: "50%", zIndex: 1 }}
            onClick={onClick}
        />
    );
}

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowCircleRightRounded
            className={className}
            style={{ ...style, fontSize: '50px', textAlign: 'center', color: '#131f32', borderRadius: "50%", zIndex: 1 }}
            onClick={onClick}
        />
    );
}

const HomePage = () => {

    const navigate = useNavigate()

    const settings = {
        className: "center",
        dots: false,
        arrows: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "10px",
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    const ProductCardsArray = [
        {
            id: 1,
            name: 'Device Location',
            description: "Device location enables personalized services and content delivery.",
            path: "/device-location",
            background: Product_Card_1_BG
        },
        {
            id: 2,
            name: 'SIM Swap',
            description: "Detect unauthorized SIM swaps for enhanced account security.",
            path: "/sim-swap-detection",
            background: Product_Card_2_BG
        },
        {
            id: 3,
            name: 'DEVICE LOCATION VERIFICATION',
            description: "Track and analyze device performance for optimal functionality.",
            path: "/device-location-monitoring",
            background: Product_Card_3_BG
        },
        {
            id: 4,
            name: 'OTP Validations',
            description: "Verify user identity securely through one-time password validation.",
            path: "/otp-validation",
            background: Product_Card_4_BG
        },
    ];


    const ValuesArray = [
        {
            title: "Innovation",
            description: "We leverage cutting-edge ideas and technologies to remain leaders in our field.",
            img: ValueIcon1,
            bgColor: "#adb7f6",
        },

        {
            title: "Commitment",
            description: "We are devoted to providing the best outcomes for our clients at every turn.",
            img: ValueIcon2,
            bgColor: "#7be0ad",
        },

        {
            title: "Quality",
            description: "Assuring constant quality in whatever we do, by  providing excellence through exceptional goods and services.",
            img: ValueIcon3,
            bgColor: "#ff4d6d",
        },

        {
            title: "Integrity",
            description: "We uphold the highest standards of honesty and ethical conduct in all our actions.",
            img: ValueIcon4,
            bgColor: "#cd9777",
        },

        {
            title: "Team Work",
            description: "Collaborating seamlessly to achieve shared goals with unity, mutual support, and collective strength.",
            img: ValueIcon5,
            bgColor: "#aaa1c8",
        },

    ]

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    return (
        <>
            <div className="HomePage">
                <div className="Header">
                    {/* <video autoPlay muted loop className="BackgroundVideo">
                        <source src={BgVideo} type="video/mp4" />
                    </video> */}
                    <img className="BackgroundVideo" src={BGIMg} alt="" />

                    <h1>Welcome to the <br /> <span> Network APIs </span> </h1>

                    {/* <p> Unlock the power of advanced network security—safeguard devices, monitor activity, and ensure seamless validation for unstoppable business growth.
                    </p> */}

                    <Button
                        id='Btn_1'
                        onClick={() => navigate('/guides')}
                    >
                        Get Started
                    </Button>

                    <p style={{ color: '#1a2547', fontWeight: '600' }} >
                        Join us and explore the endless possibilities of technology.
                    </p>
                </div>

                <div className="CountSection" >
                <div className="CountCardsBox">
                        <div className="CountCards">
                            <CountUp start={0} end={15} duration={6} suffix="+" className="CountNumber" />
                            <div className="CountLabel">Ready to use Product APIs</div>
                        </div>
                        <div className="CountCards">
                            <CountUp start={0} end={100} duration={4} suffix="+" className="CountNumber" />
                            <div className="CountLabel"><b>Free Trial APIs</b></div>
                        </div>
                        <div className="CountCards">
                            <div className="CountNumber">
                                <p>&lt;/&gt;</p>
                            </div>
                            <div className="CountLabel">Detailed documentation for each product</div>
                        </div>
                        <div className="CountCards">
                            <CountUp start={0} end={100} duration={5} suffix="%" className="CountNumber" />
                            <div className="CountLabel">Regular Updates</div>
                        </div>
                    </div>
                    <h2>
                        Leading the way in <span> intelligent connection </span> for leading service providers and <span> multinational corporations </span> with innovative, scalable edge-as-a-service <span> and Network Edge solutions. </span>
                    </h2>        
                </div>


                <div id="ProductSectionContainer">
               
                    <div className="ProductSection">
                        <h2>Our Products <span>& Services</span></h2>

                        <Slider {...settings}>
                            {ProductCardsArray.map((card) => (
                                <div key={card.id} className="ProductCard" onClick={() => navigate(card.path)}>
                                    <div id='TitleBox'>
                                        <h3>{card.name}</h3>
                                        <p>{card.description}</p>
                                    </div>
                                    <img src={card.background} alt={card.name} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    
                    <CardSection/>
                </div>

                <div className="NetworkApiSection">
                    <div className="Content">
                        <h1> Network APIs (startelelogic), the new era of digital services and mobile apps. </h1>
                        <p>This initiative aims at simplifing the developers’ experience and facilitating the integration with the networks. Through GSMA Open Gateway, telcos are defining and promoting common standards for APIs designed to provide universal access to operator networks for developers and hyperscalers.</p>
                    </div>

                    <img className='networkApiImg' src={NetworkImg} alt="NetworkImg" width='400px' />
                </div>



                <div className="ValuesSectionContainer">
                    <h2>Our Core <span> Values</span></h2>

                    <div className="ValuesSection">
                        {
                            ValuesArray.map((value, index) => {
                                return <div className="ValueRow">
                                    <div className="Circle1"
                                        style={{ backgroundColor: value.bgColor }}
                                    >
                                        <div className="Circle2">
                                            <img src={value.img} />
                                        </div>
                                    </div>
                                    <div className="ValueText">
                                        <h3 style={{ color: "#385f71" }}>{value.title}</h3>
                                        <p>
                                            {value.description}
                                        </p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default HomePage;





